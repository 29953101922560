import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getGameDetails } from "../../../../../actions/actionGamesData";
import { goToGame } from "../../../../../utils/handlers";

import GameHistory from "./game-history/game-history";
import SpinnerLoader from "../../../../misc/spinner-loader/spinner-loader";

import "./more-game-info.css";
import { Trans } from "react-i18next";
import Zapping from "../../../../misc/zapping/zapping";
const { getName } = require("country-list");

class MoreGameInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      liveStreams: [],
      tvChannels: [],
    };
  }

  componentDidMount() {
    this.props.getGameDetails(this.props.gameId).then(() => {
      this.setState({
        isFetching: false,
      });
    });
    this.getLocalStreamAndChannels();
  }

  getLocalStreamAndChannels() {
    let localStreams = [];
    let localTvChannels = [];
    if (this.props.game.zapping) {
      this.props.game.zapping.forEach((element) => {
        if (
          this.props.userLocale.toLowerCase() === element.locale.toLowerCase()
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.props.channelsById,
              element.channel_id
            ) &&
            this.props.channelsById[element.channel_id].bookmaker_name !== "" &&
            Object.prototype.hasOwnProperty.call(
              this.props.channelsById[element.channel_id],
              "afiliateLink"
            )
          ) {
            localStreams.push(element);
          }
          if (
            this.props.channelsById &&
            Object.prototype.hasOwnProperty.call(
              this.props.channelsById,
              element.channel_id
            ) &&
            this.props.channelsById[element.channel_id].bookmaker_name === ""
          ) {
            localTvChannels.push(element);
          }
        }
      });
    }
    this.setState({
      ...this.state,
      liveStreams: localStreams,
      tvChannels: localTvChannels,
    });
  }

  render() {
    if (this.state.isFetching) {
      return <SpinnerLoader small />;
    } else {
      return (
        <div className="more-game-info">
          <div className="left-cell">
            <div className="game-time d-xs-none" />
            <GameHistory
              gameId={this.props.gameId}
              team_A_id={this.props.game.team_A_id}
              team_B_id={this.props.game.team_B_id}
              events={this.props.game.events}
            />
          </div>
          {typeof this.props.game.zapping !== "undefined" && (
            <div className="right-cell">
              {this.state.liveStreams.length > 0 && (
                <div className="livestream-container">
                  <p>
                    <Trans>Free livestream</Trans>
                  </p>
                  <div className="livestream-list">
                    {
                      <Zapping
                        zapping={this.state.liveStreams}
                        numberOfstreams={this.state.liveStreams.length}
                        numberOfTvs={0}
                      />
                    }
                  </div>
                </div>
              )}

              {this.state.tvChannels.length > 0 && (
                <div className="television-container">
                  <p>
                    <Trans>On Tv in</Trans> {getName(this.props.userLocale)}
                  </p>
                  <div className="television-list">
                    {
                      <Zapping
                        zapping={this.state.tvChannels}
                        numberOfstreams={0}
                        numberOfTvs={this.state.tvChannels.length}
                      />
                    }
                  </div>
                </div>
              )}

              <button
                className="more-sugestion"
                onClick={() =>
                  goToGame(
                    this.props.history,
                    this.props.game.area_name,
                    this.props.game.competition_name,
                    this.props.game.team_A_name,
                    this.props.game.team_B_name,
                    this.props.game.match_id
                  )
                }
              >
                <Trans>
                  See all the locations with transmission for this match
                </Trans>{" "}
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGameDetails: (gameId) => {
    return dispatch(getGameDetails(gameId));
  },
});

const mapStateToProps = (state) => {
  return {
    userLocale: state.userConfigs.userCountryInfo.country_code,
    channelsById: state.zapping.channelsById,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MoreGameInfo)
);
