import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toggleFavoriteCompetitions } from "../../../actions/actionUserConfigs";
import { getCompetitionLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

import "./competition.css";

import classNames from "classnames";

class Competition extends Component {
  closeId;

  constructor(props) {
    super(props);
    this.props.openArea(this.props.isFavorite);
  }

  getCompetitionLinkInfo = () => {
    return getCompetitionLink(
      // this.props.history,
      null,
      this.props.competitionAreaName,
      this.props.competitionName,
      this.props.competitionId
    );
  };

  handleStopRipple = (event) => {
    event.stopPropagation(); // do not activate click bellow this click
  };

  changeFavorites(event, competitionId) {
    event.stopPropagation(); // do not activate click bellow this click
    event.preventDefault(); // do not follow anchor link bellow this click

    if (this.props.userChangedFavorites === false) {
      let index = this.props.countryRecommendedCompetitionsIds.indexOf(
        competitionId
      );
      if (index === -1) {
        this.props.countryRecommendedCompetitionsIds.push(competitionId);
      } else {
        this.props.countryRecommendedCompetitionsIds.splice(index, 1);
      }
      this.props.toggleFavorites(this.props.countryRecommendedCompetitionsIds);
    } else {
      this.props.toggleFavorites(competitionId);
    }
  }

  componentDidMount() {
    if (this.props.isAreaPage === true) {
      this.props.openArea(this.props.isFavorite, true);
      this.closeId = this.props.areaIdFromUrl;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.competitionAreaId !== this.competitionAreaId) {
      if (this.props.isAreaPage === true) {
        this.props.openArea(this.props.isFavorite, true);
        this.closeId = this.props.areaIdFromUrl;
      } else {
        this.props.closeArea(this.closeId);
      }
    }
  }

  render() {
    return (
      <ButtonBase
        className="competition"
        component={Link}
        to={this.getCompetitionLinkInfo()}
      >
        <div className="icon-cup"></div>
        <p className="competition-name">
          {this.props.competitionName}
        </p>
        <button
          className={classNames(
            this.props.isFavorite && "active",
            "favorite-icon"
          )}
          onMouseDown={this.handleStopRipple}
          onClick={(event) => this.changeFavorites(event, this.props.competitionId)}
        ></button>
      </ButtonBase>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleFavorites: (competitionId) => {
    dispatch(toggleFavoriteCompetitions(competitionId));
  },
});

export default connect(null, mapDispatchToProps)(Competition);
