import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { toggleFavoriteCompetitions } from "../../actions/actionUserConfigs";
import { hashDecode, checkFavorite } from "../../utils/miscellaneous";

import CompetitionInfo from "../../components/competition-info/competition-info";
import TopGames from "../../components/top-games/top-games";
import Breadcrumbs from "../../components/misc/breadcrumbs/breadcrumbs";

import "./page-competition.css";
import classNames from "classnames";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import i18n from "../../translations/i18n";

class PageCompetition extends PureComponent {
  changeFavorites(competitionId) {
    if (this.props.userChangedFavorites === false) {
      let index = this.props.countryRecommendedCompetitionsIds.indexOf(
        competitionId
      );
      if (index === -1) {
        this.props.countryRecommendedCompetitionsIds.push(competitionId);
      } else {
        this.props.countryRecommendedCompetitionsIds.splice(index, 1);
      }
      this.props.toggleFavorites(this.props.countryRecommendedCompetitionsIds);
    } else {
      this.props.toggleFavorites(competitionId);
    }
  }

  render() {
    if (this.props.isFetching) {
      return null;
    }
    return (
      <div className="default-page page-competition">
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.competition)}
          {
            <title>
              {i18n.t("titleCompetition", {
                competition: this.props.competitionData.name,
              })}
            </title>
          }
          <meta
            name="description"
            content={i18n.t("metaDescriptionCompetition", {
              competition: this.props.competitionData.name,
            })}
          ></meta>
        </Helmet>
        <div className="main-container">
          <div className="title">
            <h1>
              <span>{this.props.competitionData.area_name}</span> -{" "}
              {this.props.competitionData.name}
              <button
                onClick={() =>
                  this.changeFavorites(
                    this.props.competitionData.competition_id
                  )
                }
                className={classNames(
                  checkFavorite(
                    this.props.competitionData.competition_id,
                    this.props.userFavoriteCompetitions,
                    this.props.countryRecommendedCompetitionsIds,
                    this.props.userChangedFavorites
                  ) && "active",
                  "favorite-icon"
                )}
              />
            </h1>
            <p>{this.props.competitionData.season.name}</p>
            <Breadcrumbs
              area_name={this.props.competitionData.area_name}
              area_id={this.props.competitionData.area_id}
              competition_id={this.props.competitionData.competition_id}
              competition_name={this.props.competitionData.name}
            />
          </div>
          <CompetitionInfo params={this.props.match.params} />
          <h2 className="bottom-h1">
            <Trans
              i18nKey="topGamesToday"
              defaults="Top <0>games</0> today with broadcast"
              components={[<span></span>]}
            ></Trans>
          </h2>
          <TopGames />
          <div className="endTexts">
            <p>
              <Trans
                i18nKey="competitionTextTitle"
                values={{ competitionName: this.props.competitionData.name }}
              ></Trans>
            </p>
            <p>
              <Trans
                i18nKey="competitionTextPart1"
                values={{ competitionName: this.props.competitionData.name }}
              ></Trans>
            </p>
            <p>
              <Trans i18nKey="competitionTextPart2"></Trans>
            </p>
            <p>
              <Trans i18nKey="competitionTextPart3"></Trans>
            </p>
            <p>
              <Trans
                i18nKey="competitionTextPart4"
                values={{ competitionName: this.props.competitionData.name }}
              ></Trans>
            </p>
            <p>
              <Trans i18nKey="competitionTextPart5"></Trans>
            </p>
            <p>
              <Trans
                i18nKey="competitionTextPart6"
                values={{ competitionName: this.props.competitionData.name }}
              ></Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (state.competitionsData.allCompetitionsIds.length < 1) {
    return {
      isFetching: true,
    };
  }
  return {
    competitionData:
      state.competitionsData.allCompetitions[
        hashDecode(ownProps.match.params.competitionId)
      ],
    countryRecommendedCompetitionsIds:
      state.competitionsData.countryRecommendedCompetitionsIds,
    userChangedFavorites: state.userConfigs.userChangedFavorite,
    userFavoriteCompetitions: state.userConfigs.userFavoriteCompetitionsIds,
    isFetching: false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleFavorites: (competitionId) => {
    dispatch(toggleFavoriteCompetitions(competitionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageCompetition);
