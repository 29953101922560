import React from "react";
import { hashDecode } from "../../utils/miscellaneous";
import { getAcademiaCompetitionGames } from "../../actions/actionCompetitionsData";
import { DateTime } from "luxon";
import { withRouter } from "react-router-dom";
import SpinnerLoader from "../misc/spinner-loader/spinner-loader";
import CustomFilter from "../custom-filter/custom-filter";
import GameRow from "../table-games/competition-row/game-row/game-row";
import { Trans } from "react-i18next";

import "./competition-info.css";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

class CompetitionInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      tabIndex: 0,
      isFetching: true,
      competitionGames: [],
      filteredGames: [],
    };
  }

  componentDidMount() {
    this.getGames(hashDecode(this.props.params.competitionId), 1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tabIndex !== this.state.tabIndex &&
      this.state.tabIndex === 1
    ) {
      this.getGames(hashDecode(this.props.params.competitionId), -1);
    }
    if (
      prevState.tabIndex !== this.state.tabIndex &&
      this.state.tabIndex === 0
    ) {
      this.getGames(hashDecode(this.props.params.competitionId), 1);
    }
  }

  getGames = (compId, direction) => {
    this.setState(
      {
        isFetching: true,
      },
      () =>
        getAcademiaCompetitionGames(compId, direction)
          .then((response) => {
            this.setState({
              competitionGames: response.data.result,
              filteredGames: response.data.result,
              isFetching: false,
            });
          })
          .catch((error) => {
            console.log(`Failed to get games list. ${error}`);
            this.setState({
              competitionGames: [],
              filteredGames: [],
              isFetching: false,
            });
          })
    );
  };

  searchTeam = (e) => {
    if (e.target.value === "") {
      this.setState({
        filteredGames: this.state.competitionGames,
        searchString: "",
      });
    } else {
      let tempArray = [...this.state.competitionGames];
      let newArray = tempArray.filter(
        (game) =>
          game.team_A_name
            .toLowerCase()
            .search(e.target.value.toLowerCase()) !== -1 ||
          game.team_B_name
            .toLowerCase()
            .search(e.target.value.toLowerCase()) !== -1
      );

      this.setState({
        filteredGames: newArray,
        searchString: e.target.value,
      });
    }
  };

  setIndexNumber = (number) => {
    this.setState({
      tabIndex: number,
      searchString: "",
    });
  };

  renderEmpty = () => {
    return (
      <div className="empty-games">
        <div className="empty-image" />
        <p>
          <Trans>No results...</Trans>
        </p>
      </div>
    );
  };

  renderFilteredGames(gamesArray) {
    return gamesArray.map((each) => {
      return (
        <GameRow
          key={each.match_id}
          gameId={each.match_id}
          showOnlyLive={false}
          isCompetitionPage={true}
          gameData={each}
        />
      );
    });
  }

  renderGames() {
    if (this.state.tabIndex === 0) {
      // tab=0 render scheduled games only
      return this.renderFilteredGames(
        this.state.filteredGames.filter(
          (game) => game.status.toLowerCase() !== "played"
        )
      );
    } else {
      // tab=1 render past (status===played) games only
      return this.renderFilteredGames(
        this.state.filteredGames
          .filter((game) => game.status.toLowerCase() === "played")
          .sort((a, b) =>
            //Past games sorted from latest to oldest
            DateTime.fromISO(a.date_utc + "T" + a.time_utc) <
            DateTime.fromISO(b.date_utc + "T" + b.time_utc)
              ? 1
              : -1
          )
      );
    }
  }

  buildList() {
    return (
      <div className="table-list">
        {this.state.filteredGames.length < 1
          ? this.renderEmpty()
          : this.renderGames()}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <CustomFilter
          placeholder={"Search Team"}
          handleSearch={this.searchTeam}
          searchString={this.state.searchString}
        />
        <div className="competition-info">
          <div className="table">
            <Tabs onChange={(index) => this.setIndexNumber(index)}>
              <TabList className="tab-list">
                <Tab className="tab-selection">
                  <Trans>Fixture</Trans>
                </Tab>
                <Tab className="tab-selection">
                  <Trans>Past</Trans>
                </Tab>
              </TabList>
              {this.state.isFetching ? (
                <SpinnerLoader />
              ) : (
                <TabPanels>
                  <TabPanel>
                    {this.state.tabIndex === 0 && this.buildList()}
                  </TabPanel>
                  <TabPanel>
                    {this.state.tabIndex === 1 && this.buildList()}
                  </TabPanel>
                </TabPanels>
              )}
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CompetitionInfo);
