import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { toggleFavoriteCompetitions } from "../../../actions/actionUserConfigs";
import { flagsConverter } from "../../../utils/flag-converter/flags-converter";
import { getCompetitionLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

import GameRow from "../../../components/table-games/competition-row/game-row/game-row";
import "./competition-row.css";

import classNames from "classnames";
import { slugify } from "../../../utils/miscellaneous";

class CompetitonRow extends PureComponent {
  static defaultProps = {
    competition: {},
    competitionGamesIds: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    if (
      this.props.isFavorite ||
      this.props.showOnlyLive ||
      this.props.channel
    ) {
      this.toggleOpen();
    }
  }

  getCompetitionLinkInfo = () => {
    return getCompetitionLink(
      this.props.history,
      this.props.competition.area_name,
      this.props.competition.name,
      this.props.competition.competition_id
    );
  };

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  changeFavorites(competitionId) {
    if (this.props.userChangedFavorites === false) {
      let index = this.props.countryRecommendedCompIds.indexOf(competitionId);
      if (index === -1) {
        this.props.countryRecommendedCompIds.push(competitionId);
      } else {
        this.props.countryRecommendedCompIds.splice(index, 1);
      }
      this.props.toggleFavorites(this.props.countryRecommendedCompIds);
    } else {
      this.props.toggleFavorites(competitionId);
    }
  }

  render() {
    return this.props.isReady === true ? (
      <div className="table-competition">
        <div className="table-competition-top">
          <div className="competition-description">
            <div className="competition-icon">
              <ButtonBase
                className="competition-icon"
                component={Link}
                to={this.getCompetitionLinkInfo()}
              >
                {flagsConverter(
                  this.props.competition.competition_id,
                  this.props.competition.countrycode
                )}
              </ButtonBase>
            </div>
            <ButtonBase
              className="competition-name"
              component={Link}
              to={this.getCompetitionLinkInfo()}
            >
              {this.props.competition.area_name} - {this.props.competition.name}
            </ButtonBase>
            <button
              className={classNames(
                this.props.isFavorite && "active",
                "favorite-icon"
              )}
              onClick={() => this.changeFavorites(this.props.competitionId)}
            />
            <div
              className="competition-spacer"
              onClick={() => this.toggleOpen()}
            />
          </div>
          <div
            onClick={() => this.toggleOpen()}
            className={classNames(this.state.isOpen && "open", "expand-icon")}
          >
            <button />
          </div>
        </div>
        {this.state.isOpen && (
          <div className="table-competition-games">
            {this.props.competitionGamesIds.map((eachGameId) => {
              if (
                this.props.channel &&
                this.props.allGames[eachGameId].hasOwnProperty("zapping")
              ) {
                var haveChannel = false;
                this.props.allGames[eachGameId].zapping.forEach((zap) => {
                  if (
                    slugify(zap.zapping_name.toLowerCase()) ===
                    this.props.channel.toLowerCase()
                  ) {
                    haveChannel = true;
                  }
                });
                if (!haveChannel) {
                  return null;
                }
              }
              return (
                <GameRow
                  key={eachGameId}
                  gameId={eachGameId}
                  showOnlyLive={this.props.showOnlyLive}
                  isPageSingleChannel={this.props.isPageSingleChannel}
                />
              );
            })}
          </div>
        )}
      </div>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleFavorites: (competitionId) => {
    dispatch(toggleFavoriteCompetitions(competitionId));
  },
});

const mapStateToProps = (state, ownProps) => {
  var competitonGamesIds = [];
  if (ownProps.date) {
    competitonGamesIds =
      state.gamesData.gamesByDate[ownProps.date][ownProps.competitionId];
  } else {
    ownProps.tvGames.forEach((tvGame) => {
      if (state.gamesData.allGames[tvGame.gsm_id] &&
        state.gamesData.allGames[tvGame.gsm_id].competition_id ===
        ownProps.competitionId
      ) {
        competitonGamesIds.push(tvGame.gsm_id);
      }
    });
  }
  try {
    return {
      isReady: true,
      allGames: state.gamesData.allGames,
      competition:
        state.competitionsData.allCompetitions[ownProps.competitionId],
      competitionGamesIds: competitonGamesIds,
      countryRecommendedCompIds:
        state.competitionsData.countryRecommendedCompetitionsIds,
      userChangedFavorites: state.userConfigs.userChangedFavorite,
    };
  } catch (error) {
    return {
      isReady: false,
    };
  }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompetitonRow)
);
