export const br = {
  home: "",
  contacts: "Contatos",
  termsAndConditions: "termos-e-condicoes",
  privacyPolicy: "politica-de-privacidade",
  allCompetitions: "todas-competicoes",
  allChannels: "todos-canais",
  channel: "canal",
  competition: "competicao",
  match: "jogo",
  matches: "jogos",
};
