import {
  ALL_GAMES,
  UPDATE_VIA_WEBSOCKET,
  IS_LOADING,
  GAME_DETAILS,
  POPULAR_GAMES_BY_COUNTRY,
} from "../actions/actionGamesData";

import { adaptDataFromWebSocket } from "../utils/webSocketAdapter";

import { DateTime } from "luxon";

export const gameDataInitialState = {
  allGames: {},
  allGamesIds: [],
  gamesByDate: {},
  liveGames: [],
  topGames: [],
  isFetching: false,
};

export default function pickGamesData(state = gameDataInitialState, action) {
  // console.log("Action",action.type);
  let newState = { ...state };
  switch (action.type) {
    case ALL_GAMES: {
      const allGames = transformInObject(action.payload, action.requestedDate);
      let liveGames = state.liveGames;
      if (action.requestedDate === DateTime.local().toISODate()) {
        liveGames = Object.assign([], allGames.liveGames);
      }
      return {
        ...state,
        allGames: Object.assign(state.allGames, allGames.games),
        allGamesIds: Object.assign(state.allGamesIds, allGames.ids),
        gamesByDate: Object.assign(state.gamesByDate, {
          [action.requestedDate]: allGames.datesAndCompetitions,
        }),
        liveGames: liveGames,
        isFetching: false,
      };
    }
    case UPDATE_VIA_WEBSOCKET:
      // Game - is referent to academia API call
      // Match - is referent to webSocketData
      action.payload.forEach((match) => {
        if (
          Object.prototype.hasOwnProperty.call(state.allGames, match.match_id)
        ) {
          let newGameData = adaptDataFromWebSocket(
            state.allGames[match.match_id],
            match
          );
          let liveIndex = newState.liveGames.indexOf(match.match_id);
          if (liveIndex === -1 && newGameData.status === "Playing") {
            newState.liveGames.push(match.match_id);
          } else if (liveIndex !== -1 && newGameData.status !== "Playing") {
            newState.liveGames.splice(liveIndex, 1);
          }
          newState.allGames[match.match_id] = newGameData;
        }
      });
      return newState;
    case GAME_DETAILS: {
      const gameEvents = transformEventsInObject(action.payload);
      if (
        !newState.allGames[action.payload.match_id].hasOwnProperty("events")
      ) {
        newState.allGames[action.payload.match_id]["events"] = {};
      }
      newState.allGames[action.payload.match_id].events = Object.assign(
        state.allGames[action.payload.match_id].events,
        gameEvents
      );
      return newState;
    }

    case POPULAR_GAMES_BY_COUNTRY:
      return {
        ...state,
        topGames: correctTopGames(action.payload),
      };

    case IS_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
}

function transformInObject(data, requestedDate) {
  let traformedInObject = {
    games: {},
    ids: [],
    datesAndCompetitions: {},
    liveGames: [],
  };
  data.forEach((element) => {
    let date = element.date_utc + "T" + element.time_utc;
    let dateObject = DateTime.fromISO(date, { zone: "utc" });
    element.date_utc = dateObject.toLocal().toISODate();
    element.time_utc = dateObject.toLocal().toISOTime({
      suppressSeconds: false,
      suppressMilliseconds: true,
      includeOffset: false,
    });
    traformedInObject.games[element.match_id] = element;
    traformedInObject.ids.push(element.match_id);
    if (element.date_utc === requestedDate) {
      if (
        !traformedInObject.datesAndCompetitions.hasOwnProperty(
          element.competition_id
        )
      ) {
        traformedInObject.datesAndCompetitions[element.competition_id] = [];
      }
      traformedInObject.datesAndCompetitions[element.competition_id].push(
        element.match_id
      );
    }
    traformedInObject.datesAndCompetitions[
      "lastUpdate"
    ] = DateTime.local().toISODate();
    let liveIndex = traformedInObject.liveGames.indexOf(element.match_id);
    if (liveIndex === -1 && element.status === "Playing") {
      traformedInObject.liveGames.push(element.match_id);
    } else if (liveIndex !== -1 && element.status !== "Playing") {
      traformedInObject.liveGames.splice(liveIndex, 1);
    }
  });
  return traformedInObject;
}

function transformEventsInObject(data) {
  let eventsObject = {};
  let relevantEventCodes = ["G", "OG", "PG", "PS", "PM", "Y2C", "RC"];
  data.events.forEach((event) => {
    if (relevantEventCodes.indexOf(event.code) !== -1) {
      eventsObject[event.event_id] = event;
    }
  });
  return eventsObject;
}

function correctTopGames(data) {
  data.forEach((element) => {
    let date = element.date_utc + "T" + element.time_utc;
    let dateObject = DateTime.fromISO(date, { zone: "utc" });
    element.date_utc = dateObject.toLocal().toISODate();
    element.time_utc = dateObject.toLocal().toISOTime({
      suppressSeconds: false,
      suppressMilliseconds: true,
      includeOffset: false,
    });
  });
  return data;
}
