import { getDataFromAcademia } from "../utils/axios";

//-------Actions Types-------//
export const COUNTRY_CHANNELS = "COUNTRY_CHANNELS";
export const AFILIATE_LINKS = "AFILIATE_LINKS";
export const IS_LOADING = "IS_LOADING_ZAPPING";
export const ERROR = "ERROR";

//-------Action creators-------//

export function getCountryZapping(countryCode = null) {
  var getUrl = `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_locale_tv_channels","params":[false, "soccer"],"id":1}`;
  if (countryCode) {
    getUrl = `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_locale_tv_channels","params":["${countryCode.toLowerCase()}", "soccer"],"id":1}`;
  }
  return function (dispatch) {
    dispatch(dataForZapping(IS_LOADING));
    const request = getDataFromAcademia({
      method: "get",
      url: getUrl,
    });
    return request
      .then((response) => {
        dispatch(dataForZapping(COUNTRY_CHANNELS, response.data.result));
      })
      .catch((error) => {
        console.error(`Failed to get channels list. ${error}`);
        dispatch(dataForZapping(ERROR));
      });
  };
}

export function getAfiliateLinks() {
  return function (dispatch) {
    dispatch(dataForZapping(IS_LOADING));
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_affiliated_links","params":[],"id":6}`,
    });
    return request
      .then((response) => {
        dispatch(dataForZapping(AFILIATE_LINKS, response.data.result));
      })
      .catch((error) => {
        console.error(`Failed to get channels AFILIATE list. ${error}`);
        dispatch(dataForZapping(ERROR));
      });
  };
}

function dataForZapping(type, data) {
  return {
    type: type,
    payload: data,
  };
}
