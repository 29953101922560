import React from "react";
import { Trans } from "react-i18next";

import H2HMatch from "./h2h-match/h2h-match";

import "./head-to-head.css";

export default function HeadToHead(props) {
  return typeof props.head2head !== "undefined" &&
    props.head2head.length >= 1 &&
    props.head2head.find((each) => {
      return props.matchId !== each.match_id;
    }) ? (
    <div className="head-to-head">
      <div className="sub-title">
        <span className="icon-ball-frtv" />
        <p>
          <Trans>Head-to-head</Trans>
        </p>
      </div>
      <div className="container">
        {props.head2head.map((each) =>
          each.match_id !== props.matchId ? (
            <H2HMatch key={each.match_id} game={each} />
          ) : null
        )}
      </div>
    </div>
  ) : (
    <div className="head-to-head empty-widget d-xs-none">
      <p>
        <i>
          <Trans>No head-to-head games in last 3 years</Trans>
        </i>
      </p>
    </div>
  );
}
