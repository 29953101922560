import React from "react";
import "./game-details.css";

export default function GameDetails(props) {
  return (
    <div className="game-details">
      <div className="home-team">
        <p>{props.game.team_A_name}</p>
      </div>
      <div className="game-result">
        <p>
          {props.game.fs_A} - {props.game.fs_B}
        </p>
        {props.game.ets_A !== "" && props.game.ets_B !== "" && (
          <p>
            (Prol. {props.game.ets_A} - {props.game.ets_B})
          </p>
        )}
        {props.game.ps_A !== "" && props.game.ps_B !== "" && (
          <p>
            (Pen. {props.game.ps_A} - {props.game.ps_B})
          </p>
        )}
      </div>
      <div className="away-team">
        <p>{props.game.team_B_name}</p>
      </div>
    </div>
  );
}
