import React from "react";
import { useSelector } from "react-redux";

import Livestream from "../livestream/livestream";
import Television from "../television/televison";

import "./zapping.css";

/* class Zapping extends PureComponent{
  constructor(props){
    super(props);
  }
}



export default Zapping; */

export default function Zapping(props) {
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );
  const channelsById = useSelector((state) => state.zapping.channelsById);
  let counter = 0;

  const renderLiveStreams = () => {
    let filterByUserLocale = props.zapping.filter(
      (each) => each.locale.toLowerCase() === userLocale.toLowerCase()
    );
    let sliced = filterByUserLocale.slice(0, props.numberOfstreams);
    return sliced.map((each, index) => {
      if (
        channelsById &&
        Object.prototype.hasOwnProperty.call(channelsById, each.channel_id) &&
        channelsById[each.channel_id].bookmaker_name !== "" &&
        Object.prototype.hasOwnProperty.call(
          channelsById[each.channel_id],
          "afiliateLink"
        )
      ) {
        return (
          <Livestream
            key={index}
            data={each}
            afiliateLink={channelsById[each.channel_id].afiliateLink}
          />
        );
      } else {
        return null;
      }
    });
  };

  const renderTelevisions = () => {
    return props.zapping.map((each, index) => {
      if (
        counter < props.numberOfTvs &&
        userLocale.toLowerCase() === each.locale.toLowerCase()
      ) {
        if (
          Object.prototype.hasOwnProperty.call(each, "channel_id") &&
          channelsById &&
          Object.prototype.hasOwnProperty.call(channelsById, each.channel_id) &&
          channelsById[each.channel_id].bookmaker_name === ""
        ) {
          ++counter;
          return <Television key={index} data={each} />;
        }
      }
      return null;
    });
  };

  return typeof props.zapping !== "undefined" ? (
    <React.Fragment>
      <div className="livestream-list">{renderLiveStreams()}</div>
      {renderTelevisions()}
    </React.Fragment>
  ) : null;
}
