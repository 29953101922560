import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer, { cleanState } from "./reducers/rootReducer";
import { saveState, loadState } from "./utils/session-storage";

import "./css/index.css";
import "./css/style.css";
import "./css/font.css";

import "./translations/i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  markServiceWorkerInit,
  updateServiceWorker,
} from "./actions/actionServiceWorker";

//let persistedState = loadState(cleanState);  // was getting the saved state when refresh
let persistedState = {};

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

if (process.env.NODE_ENV === "development") {
  // hide store layout and data elements on production mode (protect our apis)
  store.subscribe(() => saveState(store.getState()));
}

/* window.addEventListener('unload', function(event) {
  localStorage.setItem("exited", "sssss")
}); */

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register({
  onSuccess: () => store.dispatch(markServiceWorkerInit()),
  onUpdate: (newSwRegistration) =>
    store.dispatch(updateServiceWorker(newSwRegistration)),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
