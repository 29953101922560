import React from "react";
import { renderBookmaker } from "../../../utils/miscellaneous";
import "./livestream.css";

import classNames from "classnames";

export default function Livestream(props) {
  return typeof props.data === "undefined" ? null : (
    <a
      className={
        !props.isNextGame
          ? classNames(
              `${renderBookmaker(props.data.zapping_name)}-background`,
              "livestream"
            )
          : "livestream_nextgames"
      }
      href={props.afiliateLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classNames(
          renderBookmaker(props.data.zapping_name),
          { "livestream-icon bm_style ico21": !props.isNextGame },
          { "livestream-icon bookmaker_small_icon": props.isNextGame }
        )}
      />
      <div className={props.isNextGame ? "" : "livestream-play"} />
    </a>
  );
}
