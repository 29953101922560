import React from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getCompetitionLink, getAreaLinkInfo } from "../../../utils/handlers";
import * as LanguageRoute from "../../../routes/languageRoutes";
import LinkGenerator from "../../../routes/linkGenerator";
import AppRoutes from "../../../routes/appRoutes";
import { ButtonBase } from "@material-ui/core";

import "./breadcrumbs.css";

export default function Breadcrumbs(props) {
  const userLanguage = useSelector((state) => state.userConfigs.userLanguage);

  const history = useHistory();

  const renderAreaName = () => {
    if (
      typeof props.area_id !== "undefined" &&
      typeof props.area_name !== "undefined"
    ) {
      return (
        <React.Fragment>
          <span>&#62;</span>
          <ButtonBase
            className="breadcrumb-text"
            component={Link}
            to={getAreaLinkInfo(history, props.area_name, props.area_id)}
          >
            {props.area_name}
          </ButtonBase>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  const renderCompetitionName = () => {
    if (
      typeof props.competition_name !== "undefined" &&
      typeof props.competition_id !== "undefined"
    ) {
      return (
        <React.Fragment>
          <span>&#62;</span>

          <ButtonBase
            className="breadcrumb-text"
            component={Link}
            to={getCompetitionLink(
              history,
              props.area_name,
              props.competition_name,
              props.competition_id
            )}
          >
            {props.competition_name}
          </ButtonBase>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  const getCorrectUserLang = () => {
    let userLang = "en";
    if (Object.prototype.hasOwnProperty.call(LanguageRoute, userLanguage)) {
      userLang = userLanguage;
    }
    return userLang;
  };

  const renderMatchTeams = () => {
    if (
      typeof props.team_A_name !== "undefined" &&
      typeof props.team_B_name !== "undefined"
    ) {
      return (
        <React.Fragment>
          <span>&#62;</span>
          <span className="breadcrumb-text">
            {props.team_A_name} vs {props.team_B_name}
          </span>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="breadcrumbs d-xs-none">
      <p>
        <ButtonBase
          className="breadcrumb-text"
          component={Link}
          to={LinkGenerator.getLinkTo(history, AppRoutes.home)}
        >
          {LanguageRoute[getCorrectUserLang()][AppRoutes.matches]}
        </ButtonBase>

        <span>&#62;</span>

        <ButtonBase
          className="breadcrumb-text"
          component={Link}
          to={LinkGenerator.getLinkTo(history, AppRoutes.allCompetitions)}
        >
          {LanguageRoute[getCorrectUserLang()][AppRoutes.allCompetitions]}
        </ButtonBase>

        {renderAreaName()}
        {renderCompetitionName()}
        {renderMatchTeams()}
      </p>
    </div>
  );
}
