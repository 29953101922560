import React, { Component } from "react";
import { connect } from "react-redux";
import { getDataFromAcademia } from "../../utils/axios";
import { todayDate, checkFavorite, slugify } from "../../utils/miscellaneous";
import { DateTime } from "luxon";

import CompetitionRow from "../../components/table-games/competition-row/competition-row";
import SpinnerLoader from "../../components/misc/spinner-loader/spinner-loader";
import TelevisionChannel from "../../components/all-channels/television-channel/television-channel";

import "./page-single-channel.css";

// import classNames from 'classnames';

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import TopGames from "../../components/top-games/top-games";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";
import { getGamesListInterval } from "../../actions/actionGamesData";
const { getName } = require("country-list");

class PageSingleChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      channelInfo: {
        competitions: [],
      },
      dateSelected: DateTime.local().toISODate(),
    };
  }

  componentDidMount() {
    if(Object.keys(this.props.allGames).length === 0){
      this.props.getAllGames().then(() => {
        if (this.props.zapping.length && !this.state.channelId) {
          this.getChannelInfo();
        }
      });
    }else{
      this.getChannelInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.url !== this.props.match.url){
      //to update when we change channel in children (TVChannel) 
      //we could pass it in props instead in here
      this.getChannelInfo();
    }
  }

  getChannelInfo() {
    if (!this.props.zapping.length) {
      return;
    }
    let channel = this.props.zapping.filter((channel) => {
      return (
        slugify(channel.name.toLowerCase()) ===
        this.props.match.params.channelName
      );
    });
    let channelId = channel[0].channel_id;
    let channelName = channel[0].name;
    this.setState({
      channelId: channelId,
      channelName: channelName,
    });
    if (channelId) {
      this.fetchData(channelId);
    }
  }

  fetchData = (channelId) => {
    this.setState(
      {
        isFetching: true,
      },
      () => {
        const request = getDataFromAcademia({
          method: "get",
          url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_zapping_by_date","params":{"sport": "soccer", "date":"${todayDate()}", "filter_by_locale": false, "filter_by_source": "", "filter_by_channel_id":${channelId}, "number_future_days":7},"id":1}`,
        });
        return request
          .then((response) => {
            let channelInfo = this.channelAdapter(response.data.result);
            this.setState({
              channelInfo,
              tvGames: response.data.result,
              isFetching: false,
            });
          })
          .catch((error) => {
            console.error(`Failed to get channels list. ${error}`);
            this.setState({
              channelInfo: {
                competitions: [],
              },
              isFetching: false,
            });
          });
      }
    );
  };

  channelAdapter = (tvGames) => {
    let competitions = [];
    tvGames.forEach((tvGame) => {
      if (
        Object.prototype.hasOwnProperty.call(this.props.allGames, tvGame.gsm_id)
      ) {
        if (
          competitions.indexOf(
            this.props.allGames[tvGame.gsm_id].competition_id
          ) === -1 &&
          tvGame.locale.toLowerCase() === this.props.userLocale.toLowerCase()
        ) {
          competitions.push(this.props.allGames[tvGame.gsm_id].competition_id);
        }
      }
    });
    return { competitions };
  };

  mergeUserFavorites() {
    let mergedCompetitionsIds = [];
    if (this.props.userFavoriteCompetitions.length === 0) {
      this.props.countryRecommendedCompetitionsIds.forEach((each) => {
        if (this.state.channelInfo.competitions.indexOf(each) !== -1) {
          mergedCompetitionsIds.push(each);
        }
      });
    } else {
      this.props.userFavoriteCompetitions.forEach((each) => {
        if (this.state.channelInfo.competitions.indexOf(each) !== -1) {
          mergedCompetitionsIds.push(each);
        }
      });
    }
    mergedCompetitionsIds = [
      ...mergedCompetitionsIds,
      ...this.state.channelInfo.competitions,
    ];
    let uniqueArray = mergedCompetitionsIds.filter(
      (item, index) => mergedCompetitionsIds.indexOf(item) === index
    );
    return uniqueArray;
  }

  buildChannelCompetitionsRow() {
    if (this.state.isFetching) {
      return <SpinnerLoader />;
    }
    let competitionsIds = this.mergeUserFavorites();
    return competitionsIds.map((eachCompetitionId) => {
      return (
        <CompetitionRow
          key={eachCompetitionId}
          competitionId={eachCompetitionId}
          isFavorite={checkFavorite(
            eachCompetitionId,
            this.props.userFavoriteCompetitions,
            this.props.countryRecommendedCompetitionsIds,
            this.props.userChangedFavorites
          )}
          channel={this.props.match.params.channelName}
          tvGames={this.state.tvGames}
          isPageSingleChannel={true}
        />
      );
    });
  }

  render() {
    return (
      <div className="default-page page-single-channel ">
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.channel)}
          <title>
            {this.state.channelName + " "}
            {i18n.t("titleChannel")}
          </title>
          <meta
            name="description"
            content={
              this.state.channelName + ": " + i18n.t("metaDescriptionChannel")
            }
          ></meta>
        </Helmet>
        <div className="main-container">
          <div className="title">
            <h1>
              <span className="tv-icon" />
              <Trans>Television guide</Trans> -
              <span>&nbsp;{this.state.channelName}</span>
            </h1>
          </div>

          {this.state.isFetching ? (
            <SpinnerLoader />
          ) : (
            <React.Fragment>
              {this.state.channelInfo.competitions.length === 0 ? (
                <div className="table empty-widget">
                  <p>
                    <i>
                      <Trans>No Games today in</Trans>
                    </i>{" "}
                    {this.state.channelName}
                  </p>
                </div>
              ) : (
                <div className="table">
                  <Tabs>
                    <TabList className="tab-list">
                      <Tab className="tab-selection">
                        {this.state.channelName}
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>{this.buildChannelCompetitionsRow()}</TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              )}
              <div className="bottom-cell">
                <div className="live-stream-list-box">
                  <h1>
                    <Trans
                      i18nKey="otherChannelsKey"
                      default="Other<0> Televisions </0> channels in"
                      components={[<span className="span-space"></span>]}
                    ></Trans>
                    &nbsp;{getName(this.props.userLocale)}
                  </h1>
                  <div className="television-list">
                    {typeof this.props.zapping !== "undefined" &&
                      this.props.zapping.sort((a,b)=>{
                        return a.name.localeCompare(b.name);
                      }).map((each) => {
                        if (
                          each.name !== this.state.channelName &&
                          each.bookmaker_name === ""
                        ) {
                          return (
                            <TelevisionChannel
                              key={each.channel_id}
                              channel={each}
                            />
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
                <div className="top-games-wrapper">
                  <h2>
                    <Trans
                      i18nKey="topGamesToday"
                      default="Top<0> games </0>today with broadcast"
                      components={[<span className="span-space"></span>]}
                    ></Trans>
                  </h2>
                  <TopGames cards={2} />
  
                </div>
              </div>
              <div className="endTexts">
                  <h3><Trans i18nKey="channelTextTitle" values={{
                    channelName:this.state.channelName,
                    countryName:getName(this.props.userLocale)
                  }}></Trans></h3>
                  <p><Trans i18nKey="channelTextPart1" values={{
                    channelName:this.state.channelName
                  }}></Trans></p>
                  <p><Trans i18nKey="channelTextPart2"></Trans></p>
                  <p><Trans i18nKey="channelTextPart3"></Trans></p>
                  <p><Trans i18nKey="channelTextPart4"></Trans></p>
                  <p><Trans i18nKey="channelTextPart5"></Trans></p>
                </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllGames: () => {
    return new Promise((resolve, reject) => {
      let dateSelected = DateTime.local().toISODate();
      let startDate = DateTime.fromISO(dateSelected)
        .minus({ days: 1 })
        .toSeconds();
      let endDate = DateTime.fromISO(dateSelected)
        .plus({ days: 6 })
        .toSeconds();
      return resolve(
        dispatch(getGamesListInterval(dateSelected, startDate, endDate))
      );
    });
  },
});

const mapStateToProps = (state) => {
  return {
    allGames: state.gamesData.allGames,
    userFavoriteCompetitions: state.userConfigs.userFavoriteCompetitionsIds,
    countryRecommendedCompetitionsIds:
      state.competitionsData.countryRecommendedCompetitionsIds,
    userChangedFavorites: state.userConfigs.userChangedFavorite,
    gamesByDate: state.gamesData.gamesByDate,
    userLocale: state.userConfigs.userCountryInfo.country_code,
    zapping: state.zapping.channels,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSingleChannel);
