import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/translations_en.json";
import translationPT from "./pt/translations_pt.json";
import translationES from "./es/translations_es.json";
import translationBR from "./br/translations_br.json";

// the translations
// https://react.i18next.com/legacy-v9/step-by-step-guide
// https://react.i18next.com/latest/using-with-hooks
const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
  es: {
    translation: translationES,
  },
  br: {
    translation: translationBR,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    lng: "en",
    saveMissing: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
