import { DateTime } from "luxon";

export function adaptDataFromWebSocket(oldGameData, socketMatchData) {
  let newGameData;
  switch (socketMatchData.type) {
    case "soccer.events":
      newGameData = oldGameData;
      newGameData = updateGameEvents(oldGameData, socketMatchData.data);
      break;
    case "soccer.match":
      newGameData = updateGame(oldGameData, socketMatchData.data);
      break;
    default:
      newGameData = oldGameData;
      break;
  }
  return newGameData;
}

function updateGame(oldGameData, socketMatchData) {
  let updatedGame = { ...oldGameData };
  let fieldsToUpdate = [
    "status",
    "winner",
    "fs_A",
    "fs_B",
    "ets_A",
    "ets_B",
    "ps_A",
    "ps_B",
    "minute",
    "minute_extra",
    "match_period",
  ];
  let date = socketMatchData.date_utc + "T" + socketMatchData.time_utc;
  let dateObject = DateTime.fromISO(date);
  Object.keys(socketMatchData).forEach((socketDataKey) => {
    if (fieldsToUpdate.indexOf(socketDataKey) !== -1) {
      updatedGame[socketDataKey] = socketMatchData[socketDataKey];
    }
  });
  if (socketMatchData.time_utc) {
    updatedGame["date_utc"] = dateObject.toISODate();
    updatedGame["time_utc"] = dateObject.toISOTime({
      suppressSeconds: false,
      suppressMilliseconds: true,
      includeOffset: false,
    });
  }
  return updatedGame;
}

function updateGameEvents(oldGameData, socketEventData) {
  let updatedGame = { ...oldGameData };
  let relevantEventCodes = [
    "SI",
    "SO",
    "YC",
    "Y2C",
    "G",
    "OG",
    "PG",
    "PSG",
    "RC",
  ];
  if (!updatedGame.hasOwnProperty("events")) {
    updatedGame.events = {};
  }
  Object.keys(socketEventData).forEach((eventId) => {
    if (relevantEventCodes.indexOf(socketEventData[eventId].code) !== -1) {
      let eventData = socketEventData[eventId];
      updatedGame.events[eventId] = eventData;
    }
  });
  return updatedGame;
}
