import React from "react";
import { Helmet } from "react-helmet";
import Mail from "../../media/mail-icon.png";

import "./page-privacy-policy.css";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";

export default function PagePrivacyPolicy() {
  return (
    <div className="default-page page-privacy-policy">
      <Helmet>
        {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.privacyPolicy)}
        <title>{i18n.t("titlePrivacyPolicy")}</title>
        <meta
          name="description"
          content={i18n.t("metaDescriptionPrivacyPolicy")}
        ></meta>
      </Helmet>
      <div className="main-container">
        <h1>
          <Trans>Privacy Policy</Trans>
        </h1>
        <p>
          <Trans i18nKey="ppIntro"></Trans>
        </p>
        <h6>
          <Trans>About personal information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppAbout"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppAbout_a"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppAbout_b"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppAbout_c"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppAbout_cookies"></Trans>
        </p>
        <h6>
          <Trans>Analytics Services</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppAnalitic"></Trans>
        </p>
        <h6>
          <Trans>Google reCaptcha</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppRecaptcha"></Trans>
        </p>
        <h6>
          <Trans>Personal identification information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppPersonal"></Trans>
        </p>
        <h6>
          <Trans>Non-personal identification information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppNonPersonal"></Trans>
        </p>
        <h6>
          <Trans>Publicly Available Information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppPublicly"></Trans>
        </p>
        <h6>
          <Trans>Uses for collected information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppUsesCollectedIntro"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_1"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_2"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_3"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_4"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_5"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_6"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_7"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_8"></Trans>
        </p>
        <p>
          <Trans i18nKey="ppUsesCollected_9"></Trans>
          <br />
          <Trans i18nKey="ppUsesCollected_9_a"></Trans>
          <br />
          <Trans i18nKey="ppUsesCollected_9_b"></Trans>
          <br />
          <Trans i18nKey="ppUsesCollected_9_c"></Trans>
          <br />
          <Trans i18nKey="ppUsesCollected_9_d"></Trans>
        </p>
        <h6>
          <Trans>Protection of your information</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppProtectionInfo"></Trans>
          <img
            className="mail-icon"
            src={Mail}
            alt="FirstRowTelevision email account"
          />
          .
        </p>
        <h6>
          <Trans>Collection of Information by Third-Party Websites</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppCollectionInfo"></Trans>
        </p>
        <h6>
          <Trans>Partner’s websites and other</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppParteners"></Trans>
        </p>
        <h6>
          <Trans>Advertising</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppAdvertising"></Trans>
        </p>
        <h6>
          <Trans>Opting-Out</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppOptingOut"></Trans>
        </p>
        <h6>
          <Trans>Access</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppAccess"></Trans>
          <img
            className="mail-icon"
            src={Mail}
            alt="FirstRowTelevision email account"
          />
          ,&nbsp;
          <Trans i18nKey="ppAccess_2"></Trans>
        </p>
        <h6>
          <Trans>Privacy policy changes</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppPolicyChanges"></Trans>
        </p>
        <h6>
          <Trans>Children’s Privacy</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppChildren"></Trans>
          <img
            className="mail-icon"
            src={Mail}
            alt="FirstRowTelevision email account"
          />
          .
        </p>
        <h6>
          <Trans>Acceptance of these Terms</Trans>
        </h6>
        <p>
          <Trans i18nKey="ppAcceptaceTerms"></Trans>
        </p>
        <h6>
          <Trans>Contacting Us</Trans>
        </h6>
        <p>
          <Trans
            i18nKey="ppContacting"
            components={[
              <img
                className="mail-icon"
                src={Mail}
                alt="FirstRowTelevision email account"
              />,
            ]}
          ></Trans>
        </p>
        <p>
          <Trans i18nKey="ppLastUpdated"></Trans>
        </p>
      </div>
    </div>
  );
}
