import { getDataFromAcademia } from "../utils/axios";

//-------Actions Types-------//
export const ALL_COMPETITIONS = "ALL_COMPETITIONS";
export const COUNTRY_COMPETITIONS = "COUNTRY_COMPETITIONS";
export const IS_LOADING = "IS_LOADING_COMPETITIONS";

//-------Action creators-------//

/**
 * Get all competitions for the actual season
 */
export function getAllCompetitions() {
  return function (dispatch) {
    dispatch(dataForTableGames(true, IS_LOADING));
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_competitions","params":{"all_seasons":false},"id":3}`,
    });
    return request
      .then((response) => {
        dispatch(dataForTableGames(response.data.result, ALL_COMPETITIONS));
      })
      .catch((error) => {
        console.error(
          `Failed to get games list. ${error} in getAllCompetitions`
        );
      });
  };
}

export function getRecommendedCompetitionsByCountry(userLocale) {
  return function (dispatch) {
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_recommended_competitions","params":["${userLocale}"],"id":4}`,
    });
    return request
      .then((response) => {
        dispatch(dataForTableGames(response.data.result, COUNTRY_COMPETITIONS));
      })
      .catch((error) => {
        console.error(
          `Failed to get games list. ${error} in getRecommendedCompetitionsByCountry`
        );
      });
  };
}

function dataForTableGames(data, type) {
  return {
    type: type,
    payload: data,
  };
}

export function getAcademiaCompetitionGames(compId, direction = 1) {
  const request = getDataFromAcademia({
    method: "get",
    url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_matches_by_competition","params":{"competitions":[${compId}], "total_days":25, "direction":${direction}},"id":1}`,
  });
  return request;
}
