import React from "react";
import { useHistory, NavLink } from "react-router-dom";

import "./menu.css";

import classNames from "classnames";
import LinkGenerator from "../../../routes/linkGenerator";
import AppRoutes from "../../../routes/appRoutes";
import { Trans } from "react-i18next";
import { ButtonBase } from "@material-ui/core";

const MenuList = (props) => {
  let history = useHistory();

  return (
    <div className={classNames("menu", props.isOpen ? "" : "menu-closed")}>
      <div className="menu-list">
        <ButtonBase
          className={"menu-list-item"}
          component={NavLink}
          activeClassName={"active"}
          exact
          to={LinkGenerator.getLinkTo(history, AppRoutes.home)}
        >
          <div className="menu-list-icon stadium-icon"></div>
          <b>
            <Trans>Games</Trans>
          </b>
        </ButtonBase>

        <ButtonBase
          className={"menu-list-item"}
          component={NavLink}
          activeClassName={"active"}
          exact
          to={LinkGenerator.getLinkTo(history, AppRoutes.allCompetitions)}
        >
          <div className="menu-list-icon cup-icon"></div>
          <b>
            <Trans>Competitions</Trans>
          </b>
        </ButtonBase>

        <ButtonBase
          className={"menu-list-item"}
          component={NavLink}
          activeClassName="active"
          exact
          to={LinkGenerator.getLinkTo(history, AppRoutes.allChannels)}
        >
          <div className="menu-list-icon tv-icon"></div>
          <b>
            <Trans>Channels</Trans>
          </b>
        </ButtonBase>
      </div>
    </div>
  );
};

export default MenuList;
