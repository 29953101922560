import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  updateViaWebSocket,
  getGamesListInterval,
} from "../../actions/actionGamesData";
import { DateTime } from "luxon";
import { subscriveGames, closeSocket } from "../../utils/webSocket";
import { checkFavorite } from "../../utils/miscellaneous";
import { Helmet } from "react-helmet";
import LinkGenerator from "../../routes/linkGenerator";
import CompetitionRow from "../../components/table-games/competition-row/competition-row";
import DateSelector from "./date-selector/date-selector";
import SpinnerLoader from "../misc/spinner-loader/spinner-loader";
import "./table-games.css";
import { Trans } from "react-i18next";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import classNames from "classnames";

const { getName } = require("country-list");

class TableGames extends PureComponent {
  static defaultProps = {
    allCompetitionsIds: [],
  };

  state = {
    dateSelected: DateTime.local().toISODate(),
    tabIndex: 0,
  };

  isFetchingDataFromWebSocket = false;
  webSocketConnected = false;
  hasGames = false;

  constructor(props) {
    super(props);
    this.props.getAllGames(this.state.dateSelected);
  }

  componentDidMount() {
    this.clearUnusedDates();
    if (!this.isFetchingDataFromWebSocket) {
      this.fetchDataFromWebSocket();
    }
  }

  componentDidUpdate() {
    if (!this.webSocketConnected) {
         this.fetchDataFromWebSocket()
    }
  }

  componentWillUnmount() {
    closeSocket();
  }

  clearUnusedDates() {
    let lastUpdateDelta = DateTime.local().minus({ days: 3 }).toISODate();
    Object.keys(this.props.gamesByDate).forEach((date) => {
      if (this.props.gamesByDate[date].lastUpdate < lastUpdateDelta) {
        Object.values(this.props.gamesByDate[date]).forEach((gameId) => {
          delete this.props.allGames[gameId];
        });
        delete this.props.gamesByDate[date];
      }
    });
  }

  fetchDataFromWebSocket() {
    let gamesIdsToSubsctive = this.getTodayGameIdsToSubscribe();
    if (this.state.dateSelected !== DateTime.local().toISODate()) {
      return;
    }
    if (gamesIdsToSubsctive.length === 0) {
      return;
    }
    this.isFetchingDataFromWebSocket = true;
    this.webSocketConnected = true;
    subscriveGames((data) => {
      this.props.updateViaWebSocket(data);
    }, gamesIdsToSubsctive).then(() => {
      this.isFetchingDataFromWebSocket = false;
    });
  }

  getTodayGameIdsToSubscribe() {
    let result = [];
    if (this.props.gamesByDate.hasOwnProperty(DateTime.local().toISODate())) {
      Object.values(
        this.props.gamesByDate[DateTime.local().toISODate()]
      ).forEach((gameIds) => {
        if (Array.isArray(gameIds)) {
          gameIds.forEach((gameId) => {
            if (result.indexOf(gameId) === -1) {
              result.push(gameId);
            }
          });
        }
      });
    }
    return result;
  }

  buildCompetitionsRow() {
    if (this.props.isFetching) {
      return <SpinnerLoader />;
    }
    let competitionsIds = this.mergeUserFavorites();
    this.hasGames = false;
    return competitionsIds.map((eachCompetitionId) => {
      if (this.props.gamesByDate.hasOwnProperty(this.state.dateSelected)) {
        if (
          this.props.gamesByDate[this.state.dateSelected].hasOwnProperty(
            eachCompetitionId
          ) &&
          this.verifyIfCompetitionHasGamesWithStreamInLocale(eachCompetitionId)
        ) {
          this.hasGames = true;
          return (
            <CompetitionRow
              key={eachCompetitionId}
              competitionId={eachCompetitionId}
              date={this.state.dateSelected}
              isFavorite={checkFavorite(
                eachCompetitionId,
                this.props.userFavoriteCompetitions,
                this.props.countryRecommendedCompetitionsIds,
                this.props.userChangedFavorites
              )}
            />
          );
        }
      }
      return null;
    });

  }

  buildLiveRow() {
    if (this.calculateNumberLiveGamesWithStream() > 0) {
      return this.mergeLiveCompetitions().map((each) => {
        if (this.verifyIfCompetitionHasLiveGames(each)) {
          return (
            <CompetitionRow
              key={each}
              competitionId={each}
              date={DateTime.local().toISODate()}
              isFavorite={checkFavorite(
                each,
                this.props.userFavoriteCompetitions,
                this.props.countryRecommendedCompetitionsIds,
                this.props.userChangedFavorites
              )}
              showOnlyLive
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div className="empty-games">
          <div className="empty-image" />
          <p>
            <Trans
              i18nKey="NoLiveGames"
              defaults="No <0>live</0> games at the moment."
              components={[<span></span>]}
            ></Trans>
          </p>
        </div>
      );
    }
  }

  buildEmptyGames(){
    return (
      <div className="empty-games">
        <div className="empty-image" />
        <p>
          <Trans
            i18nKey="NoGames"
            defaults="No games at the moment."
            components={[<span></span>]}
          ></Trans>
        </p>
      </div>
    );
  }

  verifyIfCompetitionHasGamesWithStreamInLocale(eachCompetitionId) {
    var isToShowCompetition = false;
    if (
      !this.props.gamesByDate[this.state.dateSelected].hasOwnProperty(
        eachCompetitionId
      )
    ) {
      return false;
    }
    isToShowCompetition = this.props.gamesByDate[this.state.dateSelected][
      eachCompetitionId
    ].some((gameId) => {
      if (this.props.allGames[gameId].hasOwnProperty("zapping")) {
        return this.verifyIfGameHasStreamInLocal(gameId);
      } else {
        return false;
      }
    });
    return isToShowCompetition;
  }

  verifyIfGameHasStreamInLocal(gameId) {
    var gameHasStreamInLocal = false;
    if (!this.props.allGames[gameId].hasOwnProperty("zapping")) {
      return false;
    }
    gameHasStreamInLocal = this.props.allGames[gameId].zapping.some((zap) => {
      if (
        zap.locale === this.props.userLocale.toLowerCase() &&
        this.props.channelsById &&
        Object.prototype.hasOwnProperty.call(
          this.props.channelsById,
          zap.channel_id
        )
      ) {
        if (
          this.props.channelsById[zap.channel_id].bookmaker_name !== "" &&
          Object.prototype.hasOwnProperty.call(
            this.props.channelsById[zap.channel_id],
            "afiliateLink"
          )
        ) {
          return true;
        } else if (
          this.props.channelsById[zap.channel_id].bookmaker_name === ""
        ) {
          return true;
        }
      } else {
        return false;
      }
    });
    return gameHasStreamInLocal;
  }
  /**
   * Because games are only shown if they have tv or stream
   * we must only show the competition row if it has at least one game
   * @param {*} comptitionId
   */
  verifyIfCompetitionHasLiveGames(comptitionId) {
    let hasLiveGames = false;
    hasLiveGames = this.props.gamesByDate[DateTime.local().toISODate()][
      comptitionId
    ].some((gameId) => {
      if (this.props.allGames[gameId].status.toLowerCase() === "playing") {
        return this.verifyIfGameHasStreamInLocal(gameId);
      } else {
        return false;
      }
    });
    return hasLiveGames;
  }

  calculateNumberLiveGamesWithStream() {
    let count = 0;
    this.props.liveGames.forEach((gameId) => {
      if (this.verifyIfGameHasStreamInLocal(gameId)) {
        count++;
      }
    });
    return count;
  }

  /**
   * Reorders the arrays of competions Ids in order of country recommeneded and user favorites. Favorites come in first place
   * Based on https://scotch.io/courses/the-ultimate-guide-to-javascript-algorithms/combining-arrays-without-duplicates#toc-using-filter-
   */
  mergeUserFavorites() {
    let mergedCompetitionsIds = [];
    if (this.props.userFavoriteCompetitions.length === 0) {
      mergedCompetitionsIds = [
        ...this.props.countryRecommendedCompetitionsIds,
        ...this.props.allCompetitionsIds,
      ];
    } else {
      mergedCompetitionsIds = [
        ...this.props.userFavoriteCompetitions,
        ...this.props.allCompetitionsIds,
      ];
    }
    let uniqueArray = mergedCompetitionsIds.filter(
      (item, index) => mergedCompetitionsIds.indexOf(item) === index
    );
    return uniqueArray;
  }

  mergeLiveCompetitions = () => {
    // Get each competition id from each live games from liveGames array
    let liveCompetitionsList = [];
    this.props.liveGames.forEach((each) => {
      if (
        liveCompetitionsList.indexOf(
          this.props.allGames[each].competition_id
        ) === -1
      ) {
        liveCompetitionsList.push(this.props.allGames[each].competition_id);
      }
    });

    // order live competitions by favorite
    let uniqueLiveArray = [];
    this.mergeUserFavorites().forEach((each) => {
      if (liveCompetitionsList.indexOf(each) !== -1) {
        uniqueLiveArray.push(each);
      }
    });

    return uniqueLiveArray;
  };

  changeDate = (number) => {
    this.props.getAllGames(
      DateTime.fromISO(this.state.dateSelected)
        .plus({ days: number })
        .toISODate()
    );
    this.setState({
      ...this.state,
      dateSelected: DateTime.fromISO(this.state.dateSelected)
        .plus({ days: number })
        .toISODate(),
    });
  };

  handleDatePicker = (day) => {
    this.props.getAllGames(DateTime.fromHTTP(day.toUTCString()).toISODate());
    this.setState({
      dateSelected: DateTime.fromHTTP(day.toUTCString()).toISODate(),
    });
  };

  setIndexNumber = (number) => {
    this.setState({
      tabIndex: number,
    });
  };

  render() {
    return (
      <div className="table-games">
        <Helmet>{LinkGenerator.generateCanonicalsAndAlternates()}</Helmet>
        <h2>
          <Trans
            i18nKey="soccerMatcheLiveStream"
            defaults="Soccer matches with <0>livestream</0> or television in"
            components={[<span></span>]}
          ></Trans>
          <span>{getName(this.props.userLocale)}</span>
        </h2>
        <div className="table">
          <Tabs onChange={(index) => this.setIndexNumber(index)}>
            <TabList className="tab-list">
              <Tab className="tab-selection">
                <Trans>All</Trans>
              </Tab>
              <Tab className={classNames("tab-selection")}>
                <Trans>Live</Trans>
                <div className="live-counter">
                  <b>{this.calculateNumberLiveGamesWithStream()}</b>
                </div>
              </Tab>
            </TabList>
            <DateSelector
              changeDate={this.changeDate}
              handleDatePicker={this.handleDatePicker}
              dateSelected={this.state.dateSelected}
              today={DateTime.local().toISODate()}
              tabIndex={this.state.tabIndex}
            />
            <TabPanels>
              <TabPanel>
                {this.state.tabIndex === 0 && this.buildCompetitionsRow()}
                {this.state.tabIndex === 0 && !this.hasGames && !this.props.isFetching && this.buildEmptyGames()}
              </TabPanel>
              <TabPanel>
                {this.state.tabIndex === 1 && this.buildLiveRow()}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllGames: (dateSelected) => {
    let startDate = DateTime.fromISO(dateSelected)
      .minus({ days: 1 })
      .toSeconds();
    let endDate = DateTime.fromISO(dateSelected).plus({ days: 6 }).toSeconds();
    dispatch(getGamesListInterval(dateSelected, startDate, endDate));
  },
  updateViaWebSocket: (data) => {
    dispatch(updateViaWebSocket(data));
  },
});

const mapStateToProps = (state) => {
  return {
    allCompetitionsIds: state.competitionsData.allCompetitionsIds,
    countryRecommendedCompetitionsIds:
      state.competitionsData.countryRecommendedCompetitionsIds,
    gamesByDate: state.gamesData.gamesByDate,
    userFavoriteCompetitions: state.userConfigs.userFavoriteCompetitionsIds,
    userChangedFavorites: state.userConfigs.userChangedFavorite,
    allGames: state.gamesData.allGames,
    userCountry: state.userConfigs.userCountryInfo.country_code3,
    userLocale: state.userConfigs.userCountryInfo.country_code,
    isFetching: state.gamesData.isFetching,
    liveGames: state.gamesData.liveGames,
    numberLiveGames: state.gamesData.liveGames.length,
    channelsById: state.zapping.channelsById,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableGames)
);
