import { getDataFromAcademia } from "../utils/axios";

//--------Actions Types---------//
export const ALL_GAMES = "ALL_GAMES";
export const POPULAR_GAMES_BY_COUNTRY = "POPULAR_GAMES_BY_COUNTRY";
export const UPDATE_VIA_WEBSOCKET = "UPDATE_VIA_WEBSOCKET";
export const IS_LOADING = "IS_LOADING_GAME";
export const GAME_DETAILS = "GAME_DETAILS";

//-------Action creators-------//

/**
 * Get all games for the actual day
 */
export function getGamesList(requestedDate) {
  return function (dispatch) {
    dispatch(dataForTableGames(true, IS_LOADING));
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_matches_by_date_utc","params":["${requestedDate}"],"id":1}`,
    });
    return request
      .then((response) => {
        dispatch(
          dataForTableGames(response.data.result, ALL_GAMES, requestedDate)
        );
      })
      .catch((error) => {
        console.log(`Failed to get games list. ${error}`);
      });
  };
}

/**
 * Get all games for a intervall of days (max 7).
 * (Right now the dispach in table-games is asking only the day before and the day after of requested date).
 * That way we can assure that the time zone is showing all games independently of the request
 * (In the getGamesList we connot assure that).
 */
export function getGamesListInterval(requestedDate, from, to) {
  return function (dispatch) {
    dispatch(dataForTableGames(true, IS_LOADING));
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_matches_by_timestamp_interval","params":[${from}, ${to}],"id":1}`,
    });
    return request
      .then((response) => {
        dispatch(
          dataForTableGames(response.data.result, ALL_GAMES, requestedDate)
        );
      })
      .catch((error) => {
        console.log(`Failed to get games list. ${error}`);
      });
  };
}

/**
 * Get all recommended Games for the user country
 */

export function getRecommendedGamesByContry() {
  return function (dispatch) {
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_recommended_games_with_transmission","params":["", 10, true],"id":10}`,
    });
    return request
      .then((response) => {
        dispatch(
          dataForTableGames(response.data.result, POPULAR_GAMES_BY_COUNTRY)
        );
      })
      .catch((error) => {
        console.log(`Failed to get Recommended Games By Contry. ${error}`);
      });
  };
}

/* export function getGamesByCompetition(competitionId) {
  return function (dispatch) {
    const request = getDataFromAcademia()({
      method: 'get',
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_matches_by_competition","params":{"competitions":[${competitionId}], "total_days":1, "direction":1},"id":1}`
    });
    return request
      .then(response => {
        dispatch(dataForTableGames(response.data.result, GAMES_BY_COMPETITION, competitionId))
      }).catch(error => {
        console.log(`Failed to get games list. ${error}`);
      })
  }
} */

export function getGameDetails(matchId) {
  return function (dispatch) {
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"match_details","params":["${matchId}"],"id":5}`,
    });
    return request
      .then((response) => {
        dispatch(dataForTableGames(response.data.result, GAME_DETAILS));
      })
      .catch((error) => {
        console.log(`Failed to get game details. ${error}`);
      });
  };
}

export function updateViaWebSocket(data) {
  return function (dispatch) {
    dispatch(dataForTableGames(data, UPDATE_VIA_WEBSOCKET));
  };
}

function dataForTableGames(data, type, requestedDate) {
  return {
    type: type,
    payload: data,
    requestedDate: requestedDate,
  };
}
