import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getGameLink } from "../../../../utils/handlers";

import MoreGameInfo from "../../../table-games/competition-row/game-row/more-game-info/more-game-info";

import "./game-row.css";

import classNames from "classnames";
import GameTime from "../../../misc/game-time/game-time";
import GameDetails from "../../../misc/game-details/game-details";
import Zapping from "../../../misc/zapping/zapping";
import GameStatus from "../../../misc/game-status/game-status";
import { ButtonBase } from "@material-ui/core";
import { Trans } from "react-i18next";

class GameRow extends PureComponent {
  constructor(props) {
    super(props);
    this.updateZappingLocale();
    this.state = {
      isToShowMoreGameInfo: false,
    };
  }

  toggleMoreGameInfo() {
    let toggleBool = !this.state.isToShowMoreGameInfo;
    this.setState({
      isToShowMoreGameInfo: toggleBool,
    });
  }

  checkLive = () => {
    return this.props.showOnlyLive
      ? this.props.game.status.toUpperCase() === "PLAYING"
        ? true
        : false
      : true;
  };

  /**
   * This updates the zapping channels of the game
   * So that way we only have the streams/channels that have affiliateLink or tv channel in the same local
   * Then it change the props because we was already using it
   */
  updateZappingLocale() {
    let zappingLocal = [];
    if (typeof this.props.game !== "undefined") {
      if (typeof this.props.game.zapping !== "undefined") {
        this.props.game.zapping.forEach((element) => {
          if (
            this.props.userLocale.toLowerCase() ===
              element.locale.toLowerCase() &&
            Object.prototype.hasOwnProperty.call(
              this.props.channelsById,
              element.channel_id
            )
          ) {
            if (
              this.props.channelsById[element.channel_id].bookmaker_name !==
                "" &&
              Object.prototype.hasOwnProperty.call(
                this.props.channelsById[element.channel_id],
                "afiliateLink"
              )
            ) {
              zappingLocal.push(element);
            } else if (
              this.props.channelsById[element.channel_id].bookmaker_name === ""
            ) {
              zappingLocal.push(element);
            }
          }
        });
      }
      this.props.game.zapping = zappingLocal;
    }
  }

  render() {
    const hasChannels =
      typeof this.props.game.zapping !== "undefined" &&
      this.props.game.zapping.length > 0;
    const shouldPrint =
      this.props.isCompetitionPage === true || hasChannels === true;

    return this.checkLive() && shouldPrint ? (
      <div
        className={classNames(
          "table-competition-game-item",
          this.state.isToShowMoreGameInfo && "open"
        )}
      >
        <div
          className={classNames(
            this.props.game.status.toUpperCase() === "PLAYING" && "live",
            "table-competition-game-row"
          )}
        >
          <ButtonBase
            className="left-cell"
            component={Link}
            to={getGameLink(
              this.props.history,
              this.props.game.area_name,
              this.props.game.competition_name,
              this.props.game.team_A_name,
              this.props.game.team_B_name,
              this.props.game.match_id
            )}
          >
            {this.props.isCompetitionPage || this.props.isPageSingleChannel ? (
                <div className="game-date">{this.props.game.date_utc}</div>
              ):<div></div>}
            <GameTime game={this.props.game} />
            {this.props.isCompetitionPage && (
              <div className="game-round">
                <p>{this.props.game.round_name}</p>
                <p>
                  <Trans>Game Week</Trans>
                  {this.props.game.gameweek}
                </p>
              </div>
            )}
            <GameDetails game={this.props.game} />
          </ButtonBase>
          <div className="right-cell d-xs-none">
            {!this.state.isToShowMoreGameInfo && (
              <Zapping
                zapping={this.props.game.zapping}
                numberOfstreams={2}
                numberOfTvs={1}
              />
            )}

            <div className="game-row-spacer" />
            <GameStatus />
          </div>
          {hasChannels && (
            <div
              className={classNames(
                this.state.isToShowMoreGameInfo && "open",
                "plus-icon"
              )}
              onClick={() => this.toggleMoreGameInfo()}
            >
              <button />
            </div>
          )}
        </div>
        {this.state.isToShowMoreGameInfo && (
          <MoreGameInfo gameId={this.props.gameId} game={this.props.game} />
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  if (typeof ownProps.gameData !== "undefined") {
    return {
      game: ownProps.gameData,
      userLocale: state.userConfigs.userCountryInfo.country_code,
      channelsById: state.zapping.channelsById,
    };
  } else {
    return {
      game: state.gamesData.allGames[ownProps.gameId],
      userLocale: state.userConfigs.userCountryInfo.country_code,
      channelsById: state.zapping.channelsById,
    };
  }
};

export default withRouter(connect(mapStateToProps, null)(GameRow));
