import React from "react";
import { Trans } from "react-i18next";
import "./game-time.css";

export default function Gametime(props) {
  const renderGameTime = () => {
    switch (props.game.status) {
      case "Fixture":
        return props.game.time_utc.slice(0, 5);
      case "Played":
        return (
          <React.Fragment>
            <p>
              <Trans>Finished</Trans>
            </p>
            {props.game.time_utc.slice(0, 5)}
          </React.Fragment>
        );
      case "Postponed":
        return (
          <React.Fragment>
            <p>
              <Trans>Postponed</Trans>
            </p>
            {props.game.time_utc.slice(0, 5)}
          </React.Fragment>
        );
      case "Cancelled":
        return (
          <React.Fragment>
            <p>
              <Trans>Cancelled</Trans>
            </p>
            {props.game.time_utc.slice(0, 5)}
          </React.Fragment>
        );
      case "Suspended":
        return (
          <React.Fragment>
            <p>
              <Trans>Suspended</Trans>
            </p>
            {props.game.time_utc.slice(0, 5)}
          </React.Fragment>
        );
      case "Playing":
        if (props.game.match_period === "HT") {
          return (
            <p>
              <Trans
                i18nKey="Half-Time"
                default="Half<0/>Time"
                components={[<br />]}
              ></Trans>
            </p>
          );
        }
        let extraTime =
          [45, 90, 105, 120].indexOf(props.game.minute) !== -1 &&
          props.game.minute_extra > 0
            ? " + " + props.game.minute_extra + "'"
            : "";
        let gameTime = props.game.minute + "'" + extraTime;
        return gameTime;
      default:
        return "";
    }
  };

  return <div className="game-time">{renderGameTime()}</div>;
}
