import React, { PureComponent } from "react";
import { getDataFromAcademia } from "../../utils/axios";
//import { hashDecode } from '../../utils/miscellaneous'
import CompetitionName from "../../components/misc/competition-name/competition-name";
import FullGameDescription from "../../components/full-game-description/full-game-description";
import SpinnerLoader from "../../components/misc/spinner-loader/spinner-loader";
import HeadToHead from "../../components/head-to-head/head-to-head";
import TopGames from "../../components/top-games/top-games";
import NextGames from "../../components/next-games/next-games";

import "./page-single-game.css";
import OtherTv from "../../components/other-tv/other-tv";
import Breadcrumbs from "../../components/misc/breadcrumbs/breadcrumbs";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { DateTime } from "luxon";
import { subscriveGames, closeSocket } from "../../utils/webSocket";
import { adaptDataFromWebSocket } from "../../utils/webSocketAdapter";
import i18n from "../../translations/i18n";

class PageSingleGame extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gameInfo: {},
      isFetching: true,
    };
  }

  componentDidMount() {
    this.getMatchInfo(this.props.match.params.matchId);
  }

  updateViaWebSocket(data){
    var res = adaptDataFromWebSocket(this.state.gameInfo, data[0]);
    this.setState({
      gameInfo : res
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.matchId !== this.props.match.params.matchId) {
      this.getMatchInfo(this.props.match.params.matchId);
    }
  }

  componentWillUnmount() {
    closeSocket();
  }

  getMatchInfo = (matchId) => {
    this.setState(
      {
        isFetching: true,
      },
      () => {
        const request = getDataFromAcademia({
          method: "get",
          url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"match_details","params":["${matchId}"],"id":1}`,
        });
        return request
          .then((response) => {
            this.setState({
              gameInfo: this.correctHoursOfGame(response.data.result),
              isFetching: false,
            });
            subscriveGames((data) => {
              this.updateViaWebSocket(data);
            }, this.props.match.params.matchId);
          })
          .catch((error) => {
            console.log(`Failed to get game info. ${error}`);
            this.setState({
              gameInfo: {},
              isFetching: false,
            });
          });
      }
    );
  };

  correctHoursOfGame(data) {
    let date = data.date_utc + "T" + data.time_utc;
    let dateObject = DateTime.fromISO(date, { zone: "utc" });
    data.date_utc = dateObject.toLocal().toISODate();
    data.time_utc = dateObject.toLocal().toISOTime({
      suppressSeconds: false,
      suppressMilliseconds: true,
      includeOffset: false,
    });
    return data;
  }

  render() {
    return (
      <div className="default-page page-single-game">
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.match)}
          <title>
            {this.state.gameInfo.team_A_name +
              `-` +
              this.state.gameInfo.team_B_name}{" "}
            {i18n.t("titleMatch")}
          </title>
          <meta
            name="description"
            content={i18n.t("metaDescriptionMatch", {
              game:
                this.state.gameInfo.team_A_name +
                `-` +
                this.state.gameInfo.team_B_name,
            })}
          ></meta>
        </Helmet>
        <div className="main-container">
          {this.state.isFetching ? (
            <SpinnerLoader />
          ) : (
            <React.Fragment>
              <div className="title">
                <h1>
                  <span>{this.state.gameInfo.team_A_name}</span> -{" "}
                  <span>{this.state.gameInfo.team_B_name}</span>
                </h1>
                <CompetitionName
                  area_name={this.state.gameInfo.area_name}
                  competition_id={this.state.gameInfo.competition_id}
                  competition_name={this.state.gameInfo.competition_name}
                  season_name={this.state.gameInfo.season_name}
                />
                <Breadcrumbs
                  team_A_name={this.state.gameInfo.team_A_name}
                  team_B_name={this.state.gameInfo.team_B_name}
                  match_id={this.state.gameInfo.match_id}
                  area_name={this.state.gameInfo.area_name}
                  area_id={this.state.gameInfo.area_id}
                  competition_id={this.state.gameInfo.competition_id}
                  competition_name={this.state.gameInfo.competition_name}
                />
              </div>
              <FullGameDescription gameInfo={this.state.gameInfo} />
              <div className="widgets">
                <OtherTv zapping={this.state.gameInfo.zapping} />
                <div className="right-cell">
                  <HeadToHead
                    head2head={this.state.gameInfo.head2head}
                    matchId={this.state.gameInfo.match_id}
                  />
                  <NextGames
                    area_name={this.state.gameInfo.area_name}
                    competition_id={this.state.gameInfo.competition_id}
                    competition_name={this.state.gameInfo.competition_name}
                    match_id={this.state.gameInfo.match_id}
                  />
                </div>
              </div>
              <h2>
                <Trans
                  i18nKey="topGamesToday"
                  default="Top<0> games </0>today with broadcast"
                  components={[<span></span>]}
                ></Trans>
              </h2>
              <TopGames />
              <h3>
                <Trans
                  i18nKey="singleGameTextTitle"
                  values={{
                    gameName:
                      this.state.gameInfo.team_A_name +
                      `-` +
                      this.state.gameInfo.team_B_name,
                  }}
                ></Trans>
              </h3>
              <div className="endTexts">
                <p>
                  <Trans i18nKey="singleGameTextPart1"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart2"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart3"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart4"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart5"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart6"></Trans>
                </p>
                <p>
                  <Trans i18nKey="singleGameTextPart7"></Trans>
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default PageSingleGame;
