import React, { PureComponent } from "react";

import Area from "./area/area";
import CustomFilter from "../custom-filter/custom-filter";
import "./competition-table.css";
import { connect } from "react-redux";
import { hashDecode } from "../../utils/miscellaneous";
import { Trans } from "react-i18next";

class CompetitionTable extends PureComponent {
  areasIds = [];

  static defaultProps = {
    competitionGamesIds: [],
    areasObject: {},
    areasIds: [],
    filteredCompetitions: {},
  };

  constructor(props) {
    super(props);
    this.searchCompetition = this.searchCompetition.bind(this);
    this.state = {
      areasIds: this.props.areasIds, // TODO check if this is still needed, or delete it
      filteredCompetitions: {}, // TODO check if this is still needed, or delete it
      searchString: "",
    };
  }

  render() {
    if (Object.keys(this.props.competition).length !== 0) {
      return (
        <React.Fragment>
          {!this.props.urlParams.hasOwnProperty("areaId") && (
            <CustomFilter
              handleSearch={this.searchCompetition}
              placeholder={"Search Competition"}
              searchString={this.state.searchString}
            />
          )}
          <div className="competition-table">{this.buildAreasRow()}</div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  searchCompetition(e) {
    if (e.target.value === "") {
      this.areasIds = [];
      this.setState({
        searchString: "",
      });
    } else {
      let filteredAreasIds = Object.keys(this.props.areasObject).filter(
        (areaKey) => {
          const areaName = this.props.areasObject[areaKey].name.toLowerCase();
          const filter = e.target.value.toLowerCase();
          const competitionsNames = [];
          if (areaName.search(filter) !== -1) {
            this.props.filteredCompetitions[areaKey] = {
              competitions: this.props.areasObject[areaKey].competitions,
            };
            return true;
          }
          let filteredCompetitions = Object.values(
            this.props.areasObject[areaKey].competitions
          ).filter((competition) => {
            competitionsNames.push(competition.name.toLowerCase());
            const competitionName = competition.name.toLowerCase();
            return competitionName.search(filter) !== -1;
          });
          this.props.filteredCompetitions[areaKey] = {
            competitions: filteredCompetitions,
          };
          return competitionsNames.find((name) => name.search(filter) !== -1);
        }
      );
      filteredAreasIds.sort((a, b) => {
        if (this.props.areasObject[a].name < this.props.areasObject[b].name) {
          return -1;
        }
        if (this.props.areasObject[a].name > this.props.areasObject[b].name) {
          return 1;
        }
        return 0;
      });
      this.setState({
        searchString: e.target.value,
      });
      this.areasIds = filteredAreasIds;
    }
  }

  orderAreas() {
    Object.values(this.props.competition)
      .sort(function (a, b) {
        if (a.area_name < b.area_name) {
          return -1;
        }
        if (a.area_name > b.area_name) {
          return 1;
        }
        return 0;
      })
      .forEach((competition) => {
        if (this.props.areasIds.indexOf(competition.area_id) === -1) {
          this.props.areasIds.push(competition.area_id);
        }
        if (!this.props.areasObject.hasOwnProperty(competition.area_id)) {
          this.props.areasObject[competition.area_id] = {};
          this.props.areasObject[competition.area_id].name = "";
          this.props.areasObject[competition.area_id].competitions = {};
        }
        this.props.areasObject[competition.area_id].name =
          competition.area_name;
        if (
          !this.props.areasObject[
            competition.area_id
          ].competitions.hasOwnProperty(competition.competition_id)
        ) {
          this.props.areasObject[competition.area_id].competitions[
            competition.competition_id
          ] = competition;
        }
      });
  }

  hasFilteredCompetitions() {
    let hasCompetitions = false;
    if (Object.keys(this.props.filteredCompetitions).length === 0) {
      return true;
    } else {
      Object.keys(this.props.filteredCompetitions).forEach((key) => {
        if (this.props.filteredCompetitions[key].competitions.length > 0) {
          hasCompetitions = true;
        }
      });
    }
    return hasCompetitions;
  }

  buildAreasRow() {
    this.orderAreas();
    let competitions =
      Object.keys(this.state.filteredCompetitions).length > 0
        ? this.state.filteredCompetitions
        : this.props.areasObject;
    let filteredAreas =
      this.areasIds.length !== 0 ? this.areasIds : this.state.areasIds;
    if (this.props.urlParams.hasOwnProperty("areaId")) {
      filteredAreas = [hashDecode(this.props.urlParams.areaId)];
    }
    if (!this.hasFilteredCompetitions()) {
      return (
        <div className="empty-games">
          <div className="empty-image" />
          <p>
            <Trans>No results...</Trans>
          </p>
        </div>
      );
    }
    return filteredAreas.map((areaId) => {
      return (
        <Area
          key={areaId}
          areaId={areaId}
          areaName={this.props.areasObject[areaId].name}
          areaCompetitions={competitions[areaId].competitions}
          urlParams={this.props.urlParams}
        />
      );
    });
  }
}

const mapStateToProps = (state) => {
  return {
    competition: state.competitionsData.allCompetitions,
  };
};

export default connect(mapStateToProps, null)(CompetitionTable);
