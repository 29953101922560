import {
  ALL_COMPETITIONS,
  COUNTRY_COMPETITIONS,
  IS_LOADING,
} from "../actions/actionCompetitionsData";

export const compDataInitialState = {
  allCompetitionsIds: [],
  allCompetitions: {},
  countryRecommendedCompetitionsIds: [],
  isFetching: false,
};

export default function pickCompetitionsData(
  state = compDataInitialState,
  action
) {
  switch (action.type) {
    case ALL_COMPETITIONS: {
      const allCompetitions = transformInObject(action.payload);
      return {
        ...state,
        allCompetitions: allCompetitions.competitions,
        allCompetitionsIds: allCompetitions.ids,
        isFetching: false,
      };
    }
    case COUNTRY_COMPETITIONS: {
      const competitionsIds = fetchRecommendedCompetitionsIds(action.payload);
      return {
        ...state,
        countryRecommendedCompetitionsIds: competitionsIds.recommendedIds,
      };
    }
    case IS_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
}

function transformInObject(data) {
  let traformedInObject = {
    competitions: {},
    ids: [],
  };

  data.sort(function (a, b) {
    if (a.global_display_order < b.global_display_order) {
      return -1;
    }
    if (a.global_display_order > b.global_display_order) {
      return 1;
    }
    return 0;
  });
  data.forEach((element) => {
    traformedInObject.competitions[element.competition_id] = element;
    traformedInObject.ids.push(element.competition_id);
  });
  return traformedInObject;
}

function fetchRecommendedCompetitionsIds(data) {
  let nationalRecommendedIds = [];
  let popularRecommendedIds = [];
  let result = {
    recommendedIds: [],
  };
  data.national.forEach((competition) => {
    nationalRecommendedIds.push(competition.competition_id);
  });

  data.popular_competitions.forEach((competition) => {
    popularRecommendedIds.push(competition.competition_id);
  });
  result.recommendedIds = mergeArrays(
    nationalRecommendedIds,
    popularRecommendedIds
  );
  return result;
}

function mergeArrays(firstArray, secondArray) {
  let mergedArray = [...firstArray, ...secondArray];
  let uniqueArray = mergedArray.filter(
    (item, index) => mergedArray.indexOf(item) === index
  );
  return uniqueArray;
}
