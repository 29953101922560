import Hashids from "hashids";
const hashids = new Hashids("firstRowTv");

/**
 * Slugify url to be readable
 * based in https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
 * @param {*} string
 */
export function slugify(string = "") {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function hashEncode(string = "") {
  return hashids.encode(string);
}

export function hashDecode(string = "") {
  return hashids.decode(string)[0];
}

/**
 * Verifies if Competition is in user's favorites
 * @param {The competition Id} compId
 * @param {*} userFavoriteCompetitions
 * @param {*} countryRecommendedCompetitionsIds
 * @param {*} userChangedFavorites
 */
export function checkFavorite(
  compId,
  userFavoriteCompetitions,
  countryRecommendedCompetitionsIds,
  userChangedFavorites
) {
  let isFavorite;
  if (userChangedFavorites) {
    isFavorite = userFavoriteCompetitions.indexOf(compId) !== -1 ? true : false;
  } else {
    isFavorite =
      countryRecommendedCompetitionsIds.indexOf(compId) !== -1 ? true : false;
  }
  if (userChangedFavorites && userFavoriteCompetitions.length === 0) {
    isFavorite =
      countryRecommendedCompetitionsIds.indexOf(compId) !== -1 ? true : false;
  }

  return isFavorite;
}

/**
 *
 * @param {*} name
 */
export function renderBookmaker(name) {
  let bookmakerName;
  if (name === "1XBet") {
    bookmakerName = "_1xbet";
  } else {
    bookmakerName = name.split(" ")[0].toLowerCase();
  }
  return bookmakerName;
}

/**
 * Return todays date 'yyyy-mm-dd'
 */
export function todayDate() {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return `${yyyy}-${mm}-${dd}`;
}
