import React from "react";

import "./custom-filter.css";

export default function CustomFilter(props) {
  return (
    <div className="custom-filter">
      <div className="filter-input">
        <button className="btn-search" type="button"></button>
        <input
          value={props.searchString}
          type="text"
          placeholder={props.placeholder}
          onChange={props.handleSearch}
        ></input>
      </div>
    </div>
  );
}
