import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { flagsConverter } from "../../../utils/flag-converter/flags-converter";
import { checkFavorite } from "../../../utils/miscellaneous";
import { hashDecode } from "../../../utils/miscellaneous";
import { getAreaLinkInfo } from "../../../utils/handlers";
import { Helmet } from "react-helmet";
import { ButtonBase } from "@material-ui/core";
import i18n from "../../../translations/i18n";
import Competition from "../competition/competition";

import "./area.css";

import classNames from "classnames";
import LinkGenerator from "../../../routes/linkGenerator";
import appRoutes from "../../../routes/appRoutes";

class Area extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFavorite: false,
      matchParamsAreaId: "",
      isAreaOpen: false,
    };
    this.openArea = this.openArea.bind(this);
    this.closeArea = this.closeArea.bind(this);
  }

  openArea(isFavorite, isToOpen = false) {
    if (!this.state.hasFavorite && isFavorite) {
      this.setState({
        hasFavorite: true,
      });
    }
    if (!this.state.isAreaOpen && isToOpen) {
      this.setState({
        isAreaOpen: true,
      });
    }
  }
  closeArea(areaIdToClose) {
    if (this.props.areaId === hashDecode(areaIdToClose)) {
      this.setState({
        isAreaOpen: false,
      });
    }
  }

  toggleOpen = () => {
    this.setState({
      hasFavorite: !this.state.hasFavorite,
    });
  };

  render() {
    const isAreaPage = this.props.urlParams.hasOwnProperty("areaId");
    const areaIdFromUrl = (isAreaPage === true ? this.props.urlParams.areaId : 0);

    return (
      <div
        className={classNames(
          (this.state.hasFavorite || this.state.isAreaOpen) && "open",
          "region"
        )}
      >
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(
            appRoutes.allCompetitions
          )}
          {this.props.urlParams.areaName && (
            <title>
              {this.props.areaName} {i18n.t("titleArea")}
            </title>
          )}
          <meta
            name="description"
            content={i18n.t("metaDescriptionArea", {
              area: this.props.areaName,
            })}
          ></meta>
        </Helmet>
        <div className="region-top">
          <div className="region-description">
            <ButtonBase
              component={Link}
              to={getAreaLinkInfo(
                // this.props.history,
                null,
                this.props.areaName,
                this.props.areaId
              )}
            >
              <div className="region-icon">
                {typeof this.props.areaCompetitions !== "undefined" &&
                  flagsConverter(
                    0,
                    Object.values(this.props.areaCompetitions)[0].countrycode
                  )}
              </div>
              <span className="region-name">
                {this.props.areaName}
              </span>
              <small className="region-counter">
                {Object.keys(this.props.areaCompetitions).length}
              </small>
            </ButtonBase>
            <div className="region-spacer" onClick={() => this.toggleOpen()} />
          </div>
          <div
            className={classNames(
              (this.state.hasFavorite || this.state.isAreaOpen) && "open",
              "expand-icon"
            )}
            onClick={() => this.toggleOpen()}
          >
            <button />
          </div>
        </div>
        <div className="competiton-list">
          {Object.values(this.props.areaCompetitions).map((eachCompetition) => {
            return (
              <Competition
                key={eachCompetition.competition_id}
                competitionId={eachCompetition.competition_id}
                competitionName={eachCompetition.name}
                competitionAreaId={eachCompetition.area_id}
                competitionAreaName={eachCompetition.area_name}
                isFavorite={checkFavorite(
                  eachCompetition.competition_id,
                  this.props.userFavoriteCompetitions,
                  this.props.countryRecommendedCompetitionsIds,
                  this.props.userChangedFavorites
                )}
                openArea={this.openArea}
                closeArea={this.closeArea}
                isAreaPage={isAreaPage}
                areaIdFromUrl={areaIdFromUrl}
                countryRecommendedCompetitionsIds={
                  this.props.countryRecommendedCompetitionsIds
                }
                userChangedFavorites={this.props.userChangedFavorites}
                userFavoriteCompetitions={this.props.userFavoriteCompetitions}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countryRecommendedCompetitionsIds:
      state.competitionsData.countryRecommendedCompetitionsIds,
    userChangedFavorites: state.userConfigs.userChangedFavorite,
    userFavoriteCompetitions: state.userConfigs.userFavoriteCompetitionsIds,
  };
};

export default connect(mapStateToProps, null)(Area);
