import { combineReducers } from "redux";
import GamesData, { gameDataInitialState } from "./reducerGamesData";
import CompetitionsData, {
  compDataInitialState,
} from "./reducerCompetitionsData";
import UserConfigs, { userConfInitialState } from "./reducerUserConfigs";
import Zapping, { zappingInitialState } from "./reducerZapping";
import ServiceWorker, {
  serviceWorkerInitialState,
} from "./reducerServiceWorker";

const rootReducer = combineReducers({
  gamesData: GamesData,
  competitionsData: CompetitionsData,
  userConfigs: UserConfigs,
  zapping: Zapping,
  serviceWorker: ServiceWorker,
});

export default rootReducer;

export const cleanState = {
  gamesData: gameDataInitialState,
  competitionsData: compDataInitialState,
  userConfigs: userConfInitialState,
  zapping: zappingInitialState,
  serviceWorker: serviceWorkerInitialState,
};
