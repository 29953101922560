import axios from "axios";

let baseApiUrl = "https://www.bettingacademy.co.uk";

/* export let getDataFromAcademia = () =>
  axios.create({
    baseURL: baseApiUrl,
  }); */
let multiplier = -1;
export async function getDataFromAcademia(data) {
  let requestData = {
    ...data,
    baseURL: baseApiUrl,
  };
  multiplier += 1;
  await sleep(multiplier * 100);
  //@ts-ignore
  multiplier -= 1;
  return axios(requestData);
}

export function setBaseUrl(baseUrl) {
  baseApiUrl = baseUrl;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
