import React from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getGameLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";
import Livestream from "../../misc/livestream/livestream";
import { DateTime } from "luxon";

import Television from "../../misc/television/televison";
import "./next-available-game.css";

export default function NextAvailableGame(props) {
  const history = useHistory();
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );
  const channelsById = useSelector((state) => state.zapping.channelsById);
  let zappingLocal = [];
  let tvLocal = [];
  let gameLocalDate;
  let gameLocalTime;
  updateZappingLocale();

  /**
   * This updates the zapping channels of the game
   * So that way we only have the streams/channels that have affiliateLink or tv channel in the same local
   * Then it change the props because we was already using it
   */
  function updateZappingLocale() {
    //let zappingLocal = [];
    if (props.game.zapping) {
      props.game.zapping.forEach((element) => {
        if (
          userLocale.toLowerCase() === element.locale.toLowerCase() &&
          channelsById &&
          Object.prototype.hasOwnProperty.call(channelsById, element.channel_id)
        ) {
          if (
            channelsById[element.channel_id].bookmaker_name !== "" &&
            Object.prototype.hasOwnProperty.call(
              channelsById[element.channel_id],
              "afiliateLink"
            )
          ) {
            zappingLocal.push(element);
          } else if (channelsById[element.channel_id].bookmaker_name === "") {
            tvLocal.push(element);
          }
        }
      });
    }
    return zappingLocal;
  }

  function correctHoursOfGame() {
    let date = props.game.date_utc + "T" + props.game.time_utc;
    let dateObject = DateTime.fromISO(date, { zone: "utc" });
    gameLocalDate = dateObject.toLocal().toISODate();
    gameLocalTime = dateObject.toLocal().toISOTime({
      suppressSeconds: false,
      suppressMilliseconds: true,
      includeOffset: false,
    });
  }

  return (
    <div className="next-available-game">
      {correctHoursOfGame()}
      <div className="game-date">
          <p>{gameLocalDate}</p>
          <p>{gameLocalTime.slice(0, 5)}</p>
        </div>
      <ButtonBase
        className="top-cell"
        component={Link}
        to={getGameLink(
          history,
          props.game.area_name,
          props.game.competition_name,
          props.game.team_A_name,
          props.game.team_B_name,
          props.game.match_id
        )}
      >
        <div className="home-team">
          {props.game.team_A_name}
   
          {/* <p className="away-team">{props.game.team_B_name}</p> */}
        </div>
        <div className="game-logos">
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.game.team_A_id}.png')`,
            }}
          />
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.game.team_B_id}.png')`,
            }}
          />
        </div>
        <div className="away-team">
          {props.game.team_B_name}
        </div>
      </ButtonBase>
      <div className="television-list">
        <div className="left">
          {zappingLocal.slice(0,2).map((each, index) => {
            return (
              <div key={index} className="dual_live">
                <Livestream
                  key={each.channel_id}
                  data={each}
                  afiliateLink={channelsById[each.channel_id].afiliateLink}
                  isNextGame={true}
                />
              </div>
            );
          })}
        </div>
        <div className="right">
          {tvLocal.slice(0,2).map((each, index) => {
            return (
              <Television
                key={each.channel_id}
                data={each}
                disableClick={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
