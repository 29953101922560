const appRoutes = {
  home: "home",
  contacts: "contacts",
  termsAndConditions: "termsAndConditions",
  privacyPolicy: "privacyPolicy",
  allCompetitions: "allCompetitions",
  allChannels: "allChannels",
  channel: "channel",
  competition: "competition",
  match: "match",
  matches: "matches",
};
Object.freeze(appRoutes);
export default appRoutes;
