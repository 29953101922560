import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import Livestream from "../../misc/livestream/livestream";
import Television from "../../misc/television/televison";

import "./game.css";

const { getName } = require("country-list");

export default function GameStreamsWidget(props) {
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );
  const channelsById = useSelector((state) => state.zapping.channelsById);
  const tvChannels = [];
  const liveStreams = [];
  if (Object.prototype.hasOwnProperty.call(props.topGame, "zapping")) {
    props.topGame.zapping.forEach((element) => {
      if (Object.prototype.hasOwnProperty.call(element, "channel_id")) {
        if (
          userLocale.toLowerCase() === element.locale.toLowerCase() &&
          channelsById &&
          Object.prototype.hasOwnProperty.call(
            channelsById,
            element.channel_id
          ) &&
          channelsById[element.channel_id].bookmaker_name === ""
        ) {
          tvChannels.push(element);
        }
      }
      if (
        userLocale.toLowerCase() === element.locale.toLowerCase() &&
        channelsById &&
        Object.prototype.hasOwnProperty.call(
          channelsById,
          element.channel_id
        ) &&
        channelsById[element.channel_id].bookmaker_name !== "" &&
        Object.prototype.hasOwnProperty.call(
          channelsById[element.channel_id],
          "afiliateLink"
        )
      ) {
        liveStreams.push(element);
      }
    });
  }

  const renderEmptyTvChannels = () => {
    return (
      <React.Fragment>
        <div className="game-television empty">
          <div className="separate" />
          {/* <p>On Tv in {getName(userLocale)}</p> */}
          <div className="television-list">
            <p>
              <Trans>No Tv channels</Trans>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderEmptyLiveStreams = () => {
    return (
      <React.Fragment>
        <div className="game-livestream empty">
          <div className="separate" />
          {/* <p>Free livestream</p> */}
          <div className="livestream-list">
            <p>
              <Trans>No livestream</Trans>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {liveStreams.length > 0 ? (
        <React.Fragment>
          <div className="game-livestream">
            <div className="separate" />
            <p>
              <Trans>Free livestream</Trans>
            </p>
            <div className="livestream-list">
              {liveStreams.map((each, index) => {
                return (
                  <Livestream
                    key={index}
                    data={each}
                    afiliateLink={channelsById[each.channel_id].afiliateLink}
                  />
                );
              })}
            </div>
          </div>
        </React.Fragment>
      ) : (
        renderEmptyLiveStreams()
      )}
      {tvChannels.length > 0 ? (
        <React.Fragment>
          <div className="game-television">
            <div className="separate" />
            <p>
              <Trans>On Tv in</Trans> {getName(userLocale)}
            </p>
            <div className="television-list">
              {tvChannels.map((each, index) => {
                return <Television key={index} data={each} />;
              })}
            </div>
          </div>
        </React.Fragment>
      ) : (
        renderEmptyTvChannels()
      )}
    </div>
  );
}
