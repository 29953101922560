import {
  COUNTRY_CHANNELS,
  AFILIATE_LINKS,
  IS_LOADING,
  ERROR,
} from "../actions/actionZapping";

export const zappingInitialState = {
  channels: [],
  isFetching: false,
};

export default function pickChannelsData(state = zappingInitialState, action) {
  switch (action.type) {
    case COUNTRY_CHANNELS:
      return {
        ...state,
        channels: action.payload,
        isFetching: false,
      };
    case AFILIATE_LINKS: {
      associateAfiliteLinks(state.channels, action.payload);
      let channelsObject = {};
      state.channels.forEach((channel) => {
        channelsObject[channel.channel_id] = channel;
      });
      return {
        ...state,
        channelsById: channelsObject,
        isFetching: false,
      };
    }

    case IS_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
//TODO - we should normalize data from zapping to object accordingly to the other reducers
function associateAfiliteLinks(channels, afiliateData) {
  var channelNames = [];
  channels.forEach((channel) => {
    if (channelNames.indexOf(channel.name) === -1) {
      channelNames.push(channel.name.toLowerCase());
    }
  });
  afiliateData.forEach((afiliate) => {
    if (channelNames.indexOf(afiliate.slug) !== -1) {
      channels[channelNames.indexOf(afiliate.slug)].afiliateLink =
        afiliate.bm_url;
    }
  });
}
