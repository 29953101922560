import React, { useState } from "react";
import DayPicker from "react-day-picker";

import "./date-selector-style.css";
import "react-day-picker/lib/style.css";

import classNames from "classnames";
import { Trans } from "react-i18next";

export default function DateSelector(props) {
  const [openDate, setOpenDate] = useState(false);

  const handleDatePicker = (day) => {
    props.handleDatePicker(day);
    setOpenDate(false);
  };

  const renderDay = () => {
    return props.dateSelected === props.today ? (
      <Trans>Today</Trans>
    ) : (
      props.dateSelected
    );
  };

  const modifiers = {
    highlighted: new Date(props.dateSelected),
  };

  const selectedStyle = `.DayPicker-Day--highlighted {
    background-color: white;
    color: black;
  }`;

  return (
    <div
      className={classNames("date-selector", props.tabIndex === 1 && "d-none")}
    >
      <button
        className="arrow-icon date-selector-left"
        onClick={() => props.changeDate(-1)}
      />
      <button className="calendar-date" onClick={() => setOpenDate(true)}>
        <span className="calendar-icon" />
        {renderDay()}
      </button>
      <button
        className="arrow-icon date-selector-right"
        onClick={() => props.changeDate(1)}
      />
      {openDate && (
        <React.Fragment>
          <div
            className="date-picker-wrapper"
            onClick={() => setOpenDate(false)}
          />
          <div className="date-picker">
            <style>{selectedStyle}</style>
            <DayPicker
              showOutsideDays
              onDayClick={handleDatePicker}
              modifiers={modifiers}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
