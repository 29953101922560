import React from "react";

import Mail from "../../media/mail-icon.png";

import "./page-contacts.css";
import { Helmet } from "react-helmet";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";

export default function PageContacts() {
  return (
    <div className="default-page page-contacts">
      <Helmet>
        {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.contacts)}
        <title>{i18n.t("titleContacts")}</title>
        <meta
          name="description"
          content={i18n.t("metaDescriptionContacts")}
        ></meta>
      </Helmet>
      <div className="main-container">
        <h1>
          <Trans>Contacts</Trans>
        </h1>
        <h2>
          <Trans i18nKey="contacts1PartTitle"></Trans>
        </h2>
        <p>
          <Trans i18nKey="contacts1Part"></Trans>
        </p>
        <p>
          <Trans i18nKey="contacts2Part"></Trans>
        </p>
        <h3>
          <Trans i18nKey="contacts2.1PartTitle"></Trans>
        </h3>
        <p>
          <Trans i18nKey="contacts2.1Part"></Trans>
        </p>
        <p>
          <Trans i18nKey="contacts3Part"></Trans>
        </p>
        <h3>
          <Trans i18nKey="contacts4PartTitle"></Trans>
        </h3>
        <p>
          <Trans i18nKey="contacts4Part"></Trans>
        </p>
        <p>
          <Trans i18nKey="contacts5Part"></Trans>
        </p>
        <p>
          <Trans>Please send us an email to</Trans>{" "}
          <img
            className="mail-icon"
            src={Mail}
            alt="First Row Television email contact"
          />{" "}
          .
        </p>
      </div>
    </div>
  );
}
