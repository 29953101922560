import React from "react";
import FlagIconFactory from "react-flag-icon-css";

import "./flags-converter.css";

const getCountryISO2 = require("country-iso-3-to-2");

/**
 *
 *  Selects custom flags for certain soccer competitons
 *
 * @param {*} compId
 * @param {*} countryCode
 *
 */

export const flagsConverter = (compId, countryCode) => {
  switch (compId) {
    case 11997260:
      return <span className="special-flag uefa" />;
    case 18:
      return <span className="special-flag europa-league" />;
    case 10:
      return <span className="special-flag champions-league" />;
    case 251:
      return <span className="special-flag afc-champions-league" />;
    case 253:
      return <span className="special-flag afc-cup" />;
    case 255:
      return <span className="special-flag concacaf-cl" />;
    case 297:
      return <span className="special-flag copa-sudamericana" />;
    case 278:
      return <span className="special-flag ofc-cl" />;
    case 241:
      return <span className="special-flag copa-libertadores" />;
    case 732:
    case 430:
      return <span className="special-flag friendlies" />;
    case 1661:
      return <span className="special-flag nations-league" />;
    default:
      if (typeof countryCode !== "undefined" && countryCode !== "") {
        let convertedCountryCode;
        switch (countryCode) {
          case "RSA":
            convertedCountryCode = getCountryISO2("ZAF");
            break;
          case "ALG":
            convertedCountryCode = getCountryISO2("DZA");
            break;
          case "KSA":
            convertedCountryCode = getCountryISO2("SAU");
            break;
          case "UAE":
            convertedCountryCode = getCountryISO2("ARE");
            break;
          case "ANG":
            convertedCountryCode = getCountryISO2("AGO");
            break;
          case "MAS":
            convertedCountryCode = getCountryISO2("MYS");
            break;
          case "SIN":
            convertedCountryCode = getCountryISO2("SGP");
            break;
          case "VIE":
            convertedCountryCode = getCountryISO2("VNM");
            break;
          case "ENG":
            convertedCountryCode = "gb-eng";
            break;
          case "SCO":
            convertedCountryCode = "gb-sct";
            break;
          case "WAL":
            convertedCountryCode = "gb-wls";
            break;
          case "NIR":
            convertedCountryCode = "gb-nir";
            break;
          default:
            convertedCountryCode = getCountryISO2(countryCode);
        }
        if (convertedCountryCode == null) {
          return <span className="flag-icon default-flag" />;
        }
        return simpleFlag(convertedCountryCode.toLowerCase());
      } else {
        return <span className="flag-icon default-flag" />;
      }
  }
};

export const simpleFlag = (countrycode) => {
  let adaptedFlag;

  if (countrycode === "uk") {
    adaptedFlag = "gb";
  } else {
    adaptedFlag = countrycode;
  }
  const FlagIcon = FlagIconFactory(React, {
    useCssModules: false,
  });

  return <FlagIcon code={adaptedFlag.toLowerCase()} Component={"span"} />;
};
