import React from "react";
import "./television.css";
import { useHistory, Link } from "react-router-dom";
import { getChannelLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

export default function Television(props) {
  const history = useHistory();
  return typeof props.data === "undefined" ? null : (
    <ButtonBase
      className="television"
      component={Link}
      to={getChannelLink(history, props.data.zapping_name)}
    >
      <div className="television-icon" />
      <span>{props.data.zapping_name}</span>
    </ButtonBase>
  );
}
