export const loadState = (defaultFieldsOfState) => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    const parsedSerializedState = JSON.parse(serializedState);
    Object.keys(defaultFieldsOfState).forEach((reducerName) => {
      if (!parsedSerializedState.hasOwnProperty(reducerName)) {
        parsedSerializedState[reducerName] = defaultFieldsOfState[reducerName];
      } else {
        Object.keys(defaultFieldsOfState[reducerName]).forEach((key) => {
          if (!parsedSerializedState[reducerName].hasOwnProperty(key)) {
            parsedSerializedState[reducerName][key] =
              defaultFieldsOfState[reducerName][key];
          }
        });
      }
    });
    return parsedSerializedState;
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (error) {
    // ignore write errors
  }
};

export const saveUserConfigs = (object, reducerName) => {
  try {
    let state = JSON.parse(localStorage.getItem("state"));
    if (state === null) {
      state = {};
    }
    state[reducerName] = object;
    localStorage.setItem("state", JSON.stringify(state));
    localStorage.setItem(
      "configs",
      JSON.stringify({ [reducerName]: state[reducerName] })
    );
  } catch (error) {}
};
