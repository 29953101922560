import {
  VERIFY_USER_CONFIGS,
  TOGGLE_FAVORITE_COMPETITION,
  USER_COUNTRY_INFO,
} from "../actions/actionUserConfigs";
import { saveUserConfigs } from "../utils/session-storage";

export const userConfInitialState = {
  userFavoriteCompetitionsIds: [],
  userChangedFavorite: false,
  userCountryInfo: {},
  userLanguage: navigator.language || navigator.userLanguage,
};

export default function handleUserConfigs(
  state = userConfInitialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case TOGGLE_FAVORITE_COMPETITION:
      if (state.userChangedFavorite === false) {
        newState.userFavoriteCompetitionsIds = action.payload;
        newState.userChangedFavorite = true;
        saveUserConfigs(newState, "userConfigs");
        return newState;
      } else {
        let indexToHandle = state.userFavoriteCompetitionsIds.indexOf(
          action.payload
        );
        if (indexToHandle === -1) {
          newState.userFavoriteCompetitionsIds.push(action.payload);
          newState.userFavoriteCompetitionsIds = [
            ...newState.userFavoriteCompetitionsIds,
          ];
          saveUserConfigs(newState, "userConfigs");
        } else {
          newState.userFavoriteCompetitionsIds.splice(indexToHandle, 1);
          newState.userFavoriteCompetitionsIds = [
            ...newState.userFavoriteCompetitionsIds,
          ];
          saveUserConfigs(newState, "userConfigs");
        }
      }
      return newState;
    case VERIFY_USER_CONFIGS:
      saveUserConfigs(newState, "userConfigs");
      return newState;
    case USER_COUNTRY_INFO: {
      newState.userCountryInfo = action.payload.geoip;
      let userLanguage = "en";
      if (
        action.payload.domain_list.hasOwnProperty(
          action.payload.detected_language_code
        )
      ) {
        userLanguage =
          action.payload.domain_list[action.payload.detected_language_code]
            .language;
      }
      newState.userLanguage = userLanguage;
      saveUserConfigs(newState, "userConfigs");
      return newState;
    }
    default:
      return state;
  }
}
