import React, { PureComponent } from "react";
import swal from "@sweetalert/with-react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18n from "./translations/i18n";
import {
  getAllCompetitions,
  getRecommendedCompetitionsByCountry,
} from "./actions/actionCompetitionsData";
import {
  verifyUserConfigs,
  getUserCountryInfo,
} from "./actions/actionUserConfigs";
import { getCountryZapping, getAfiliateLinks } from "./actions/actionZapping";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import LandingPage from "./views/landingPage";
import { Helmet } from "react-helmet";
import * as LanguageRoute from "./routes/languageRoutes";
import LinkGenerator from "./routes/linkGenerator";
import { getRecommendedGamesByContry } from "./actions/actionGamesData";
import { updateServiceWorker } from "./actions/actionServiceWorker";

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.props.verifyUserConfigs();
    this.updateServiceWorker = this.updateServiceWorker.bind(this);
    this.props.getUserCountryInfo().then(() => {
      this.verifyLinkAndRedirect();
      this.props.getCompetitions();
      this.props.getRecommendedCompetitions(this.props.userLanguage);
    });
    this.props.getRecommendedGamesByContry();
    this.props.getCountryZapping();
  }


  verifyLinkAndRedirect(){
    let userLanguage = Object.prototype.hasOwnProperty.call(
      LanguageRoute,
      this.props.userLanguage
    )
      ? this.props.userLanguage
      : "en";
    if(this.props.history.location.pathname.split("/")[1] !== userLanguage){
      var dest = this.props.history.location.pathname.replace(this.props.history.location.pathname.split("/")[1],userLanguage);
      this.props.history.replace(dest);
    }
  }

  componentDidCatch(error, info) {
    //TODO write to a file or send mail
    console.log("Error to be sent to mail in the future", error, info);
  }

  updateServiceWorker() {
    const registrationWaiting = this.props.serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          localStorage.clear();
          window.location.reload();
        }
      });
    }
  }

  showUpdateAlert() {
    if (this.props.isServiceWorkerUpdated) {
      swal({
        title: i18n.t("A new Version is available"),
        text: i18n.t("An update will be done after click!"),
        buttons: {
          ok: {
            text: "Ok",
          },
        },
      }).then((e) => {
        this.updateServiceWorker();
      });
    }
  }

  render() {
    i18n.changeLanguage(this.props.userLanguage);
    if (this.props.userCountry) {
      let userCountry = this.props.userCountry.toLowerCase();
      let userLanguage = Object.prototype.hasOwnProperty.call(
        LanguageRoute,
        this.props.userLanguage
      )
        ? this.props.userLanguage
        : "en";
      LinkGenerator.setUserLocal(userLanguage, userCountry);
      this.showUpdateAlert();
      return (
        <div className="App">
          {/*           <p>
            isServiceWorkerInitialized3:{' '}
            {JSON.stringify(this.props.isServiceWorkerInitialized)}
          </p>
          <p>
            isServiceWorkerUpdated:{' '}
            {JSON.stringify(this.props.isServiceWorkerUpdated)}
          </p>
          {this.props.isServiceWorkerUpdated && (
            <button
              buttonText="Update"
              onClick={this.updateServiceWorker}
            >There is a new version available.</button>
          )} */}
          <Header />
          <Helmet>
            <html lang={userLanguage} />
            <title>{i18n.t("title")}</title>
            <meta name="keywords" content={i18n.t("metaKeyWords")}></meta>
            <meta name="description" content={i18n.t("metaDescription")}></meta>
            <meta property="og:title" content={i18n.t("ogTitle")}></meta>
            <meta
              property="og:description"
              content={i18n.t("ogDescription")}
            ></meta>
          </Helmet>
              <LandingPage
                userCountry={userCountry}
                userLanguage={userLanguage}
              />
          <Footer />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userCountry: state.userConfigs.userCountryInfo.country_code,
    userCountry3: state.userConfigs.userCountryInfo.country_code3,
    userLanguage: state.userConfigs.userLanguage,
    isServiceWorkerInitialized: state.serviceWorker.serviceWorkerInitialized,
    isServiceWorkerUpdated: state.serviceWorker.serviceWorkerUpdated,
    serviceWorkerRegistration: state.serviceWorker.serviceWorkerRegistration,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompetitions: () => {
    dispatch(getAllCompetitions());
  },
  verifyUserConfigs: () => {
    dispatch(verifyUserConfigs());
  },
  getRecommendedCompetitions: (country) => {
    dispatch(getRecommendedCompetitionsByCountry(country));
  },
  getRecommendedGamesByContry: () => {
    dispatch(getRecommendedGamesByContry());
  },
  getUserCountryInfo: () => {
    return dispatch(getUserCountryInfo());
  },
  updateServiceWorkerData: (newServiceWorker) => {
    dispatch(updateServiceWorker(newServiceWorker));
  },
  getCountryZapping: () => {
    dispatch(getCountryZapping()).then(() => {
      dispatch(getAfiliateLinks());
    });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
