//-------Actions Types-------//
export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

//-------Action creators-------//

export function markServiceWorkerInit() {
  return function (dispatch) {
    console.log(SW_INIT);
    dispatch(handleServicerWorkerState(null, SW_INIT));
  };
}

export function updateServiceWorker(newServiceWorker) {
  return function (dispatch) {
    console.log(SW_UPDATE);
    dispatch(handleServicerWorkerState(newServiceWorker, SW_UPDATE));
  };
}

function handleServicerWorkerState(data, type) {
  return {
    type: type,
    payload: data,
  };
}
