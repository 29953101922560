import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageGames from "./page-games/page-games";
import PageAllCompetitions from "./page-all-competitions/page-all-competitions";
import PageAllChannels from "./page-all-channels/page-all-channels";
import PageContacts from "./page-contacts/page-contacts";
import PageTermsAndConditions from "./page-terms-and-conditions/page-terms-and-conditions";
import PagePrivacyPolicy from "./page-privacy-policy/page-privacy-policy";
import PageCompetition from "./page-competition/page-competition";
import PageSingleGame from "./page-single-game/page-single-game";
import PageSingleChannel from "./page-single-channels/page-single-channel";
import AppRoutes from "../routes/appRoutes";
import * as LanguageRoute from "../routes/languageRoutes";

const LandingPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  });

  const userLanguage = props.userLanguage;
  return (
    <Switch>
      <Redirect exact from="/" to={userLanguage + "/"} />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.contacts]
        }
        component={PageContacts}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.termsAndConditions]
        }
        component={PageTermsAndConditions}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.privacyPolicy]
        }
        component={PagePrivacyPolicy}
      />
      <Route
        exact
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.allCompetitions]
        }
        component={PageAllCompetitions}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.allCompetitions] +
          "/:areaName/:areaId"
        }
        component={PageAllCompetitions}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.allChannels]
        }
        component={PageAllChannels}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.competition] +
          "/:areaName/:competitionName/:competitionId"
        }
        component={PageCompetition}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.match] +
          "/:areaName/:competitionName/:teamA/:teamB/:matchId"
        }
        component={PageSingleGame}
      />
      <Route
        path={
          "/" +
          userLanguage +
          "/" +
          LanguageRoute[userLanguage][AppRoutes.channel] +
          "/:channelName"
        }
        component={PageSingleChannel}
      />
      <Route exact path={"/:lang([a-z]{2})"} component={PageGames} />
      {/* <Route exact path={'*'} component={PageNotFound} /> */}
    </Switch>
  );
};

export default LandingPage;
