import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { flagsConverter } from "../../../utils/flag-converter/flags-converter";
import { getCompetitionLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

export default function CompetitionName(props) {
  const history = useHistory();
  const countrycode = useSelector((state) =>
    Object.keys(state.competitionsData.allCompetitions).length
      ? state.competitionsData.allCompetitions[props.competition_id].countrycode
      : ""
  );

  return (
    <p className="competition-name">
      <ButtonBase
        component={Link}
        to={getCompetitionLink(
          history,
          props.area_name,
          props.competition_name,
          props.competition_id
        )}
      >
        {flagsConverter(props.competition_id, countrycode)}
        {props.competition_name}
      </ButtonBase>
    </p>
    // competition season removed for future implementation {props.season_name}
  );
}
