import { getDataFromAcademia, setBaseUrl } from "../utils/axios";

//-------Actions Types-------//
export const VERIFY_USER_CONFIGS = "VERIFY_USER_CONFIGS";
export const TOGGLE_FAVORITE_COMPETITION = "TOGGLE_FAVORITE_COMPETITION";
export const USER_COUNTRY_INFO = "USER_COUNTRY_INFO";

//-------Action creators-------//
export function verifyUserConfigs() {
  return function (dispatch) {
    dispatch(handleUserConfigs(null, VERIFY_USER_CONFIGS));
  };
}

export function toggleFavoriteCompetitions(competitionId) {
  return function (dispatch) {
    dispatch(handleUserConfigs(competitionId, TOGGLE_FAVORITE_COMPETITION));
  };
}

export function getUserCountryInfo() {
  return function (dispatch) {
    const request = getDataFromAcademia({
      method: "get",
      url: `api/?mode=web&jsonrpc={"id":1,"jsonrpc":2.0,"method":"api_geo_settings","params":{}}`,
    });
    return request
      .then((response) => {
        let languageCode = response.data.result.detected_language_code;
        let requestDomain = Object.prototype.hasOwnProperty.call(
          response.data.result.domain_list,
          languageCode
        )
          ? response.data.result.domain_list[languageCode].domain
          : "en";
        setBaseUrl(requestDomain);
        dispatch(handleUserConfigs(response.data.result, USER_COUNTRY_INFO));
      })
      .catch((error) => {
        console.log(`Failed to get user country. ${error}`);
      });
  };
}

function handleUserConfigs(data, type) {
  return {
    type: type,
    payload: data,
  };
}
