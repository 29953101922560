import React from "react";

import "./game-history.css";

import classNames from "classnames";

export default function GameHistory(props) {
  const renderEvents = (event) => {
    let extraTime =
      [45, 90, 105, 120].indexOf(event.minute) !== -1 && event.minute_extra > 0
        ? event.minute_extra + "'"
        : "";
    let gameTime = extraTime === "" ? event.minute + "'" : "+" + extraTime;

    switch (event.code) {
      case "G":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="icon-ball-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span
                className={classNames(
                  extraTime !== "" && "extra-time",
                  "minute"
                )}
              >
                {gameTime}
              </span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="icon-ball-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "PG":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="icon-penalty-goal-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="icon-penalty-goal-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "PS":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="icon-defended-penalty-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="icon-defended-penalty-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "PM":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="icon-fail-penalty-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="icon-fail-penalty-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "OG":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="icon-ball-frtv own-goal" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="icon-ball-frtv own-goal" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "Y2C":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person}{" "}
                  <span className="yellow-card icon-two-yellow-card-frtv" />{" "}
                  <span className="red-card icon-card-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="yellow-card icon-two-yellow-card-frtv" />{" "}
                  <span className="red-card icon-card-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      case "RC":
        return (
          <div key={event.event_id} className="game-history-item">
            <div className="home-team-history">
              {event.team_id === props.team_A_id && (
                <p>
                  {event.person} <span className="red-card icon-card-frtv" />
                </p>
              )}
            </div>
            <div className="game-minute">
              <span className="minute">{gameTime}</span>
            </div>
            <div className="away-team-history">
              {event.team_id === props.team_B_id && (
                <p>
                  <span className="red-card icon-card-frtv" /> {event.person}
                </p>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return props.events && Object.values(props.events).length > 0 ? (
    <div className="game-history">
      {Object.values(props.events).map((event) => {
        return renderEvents(event);
      })}
    </div>
  ) : null;
}
