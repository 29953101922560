import React from "react";

import TelevisionChannel from "../../components/all-channels/television-channel/television-channel";
import LiveStreamChannel from "../../components/all-channels/livestream-channel/livestream-channel";
import "./page-all-channels.css";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Helmet } from "react-helmet";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";
const { getName } = require("country-list");

class PageAllChannels extends PureComponent {
  render() {
    if (this.props.isFetching) {
      return null;
    }
    return (
      <div className="page-default page-all-channels">
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(appRoutes.allChannels)}
          <title>{i18n.t("titleAllChannels")}</title>
          <meta
            name="description"
            content={i18n.t("metaDescriptionAllChannels")}
          ></meta>
        </Helmet>
        <div className="main-container">
          <div className="live-stream-list-box">
            <h1>
              <Trans
                i18nKey="freeLiveStream"
                defaults="<0>Free</0> livestream <1>in bookmakers in </1>"
                components={[<span></span>, <span></span>]}
              ></Trans>
              {getName(this.props.userLocale)}
            </h1>
            <div className="live-stream-list">
              {typeof this.props.channels !== "undefined" &&
                this.props.channels.map((each) => {
                  if (
                    each.bookmaker_name !== "" &&
                    Object.prototype.hasOwnProperty.call(each, "afiliateLink")
                  ) {
                    return (
                      <LiveStreamChannel
                        key={each.channel_id}
                        channel={each}
                        afiliateLink={each.afiliateLink}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className="live-stream-list-box">
            <h2>
              <Trans
                i18nKey="televisionsStream"
                defaults="Television <0>channels in </0>"
                components={[<span></span>]}
              ></Trans>
              {getName(this.props.userLocale)}
            </h2>
            <div className="television-list">
              {typeof this.props.channels !== "undefined" &&
                this.props.channels.map((each) => {
                  if (each.bookmaker_name === "") {
                    return (
                      <TelevisionChannel key={each.channel_id} channel={each} />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className="endTexts">
            <h3>
              <Trans i18nKey="allChannelsBottomTitle1"></Trans>
            </h3>
            <p>
              <Trans i18nKey="allChannelsBottomText1"></Trans>
            </p>
            <h3>
              <Trans i18nKey="allChannelsBottomTitle2"></Trans>
            </h3>
            <p>
              <Trans i18nKey="allChannelsBottomText2"></Trans>
            </p>
            <p>
              <Trans i18nKey="allChannelsBottomText2.1"></Trans>
            </p>
            <p>
              <Trans i18nKey="allChannelsBottomText2.2"></Trans>
            </p>
            <p>
              <Trans i18nKey="allChannelsBottomText2.3"></Trans>
            </p>
            <h3>
              <Trans i18nKey="allChannelsBottomTitle3"></Trans>
            </h3>
            <p>
              <Trans i18nKey="allChannelsBottomText3"></Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let channelsOrderd;
  if (state.zapping.channelsById) {
    channelsOrderd = Object.values(state.zapping.channelsById).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
  return {
    zapping: state.zapping.channels,
    userLocale: state.userConfigs.userCountryInfo.country_code,
    channels: channelsOrderd,
  };
};

export default connect(mapStateToProps, null)(PageAllChannels);
