import { SW_INIT, SW_UPDATE } from "../actions/actionServiceWorker";

export const serviceWorkerInitialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

export default function handleServicerWorkerState(
  state = serviceWorkerInitialState,
  action
) {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
        serviceWorkerUpdated: false,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: action.payload ? true : false,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
}
