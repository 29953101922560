import React from "react";
import { useHistory, Link } from "react-router-dom";
import { getGameLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

import GameDetails from "../../misc/game-details/game-details";

import "./h2h-match.css";

export default function H2HMatch({ game }) {
  const history = useHistory();

  return (
    <div className="h2h-match">
      <div className="game-date">{game.date_utc}</div>
      <ButtonBase
        className="television"
        component={Link}
        to={getGameLink(
          history,
          game.area_name,
          game.competition_name,
          game.team_A_name,
          game.team_B_name,
          game.match_id
        )}
      >
        <div className="game-competition">
          <p>{game.competition_name}</p>
          <p>{game.season_name}</p>
        </div>
        <GameDetails game={game} />
      </ButtonBase>
    </div>
  );
}
