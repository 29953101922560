import React from "react";
import { useHistory, Link } from "react-router-dom";

import "./television-channel.css";
import { getChannelLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";

export default function TelevisionChannel(props) {
  const history = useHistory();

  return (
    <ButtonBase
      className="television-channel"
      component={Link}
      to={getChannelLink(history, props.channel.name)}
    >
      <div className="tv-icon"></div>
      <p>{props.channel.name}</p>
    </ButtonBase>
  );
}
