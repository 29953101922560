import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { adaptChannelsByCountry } from "../../utils/channelAdapter";

import OtherTvRow from "./other-tv-row/other-tv-row";

import "./other-tv.css";

export default function OtherTv(props) {
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );

  const renderWarning = () => {
    return (
      <div className="other-tv empty-widget d-xs-none">
        <p>
          <i>
            <Trans>No channels in other countries to watch the match</Trans>
          </i>
        </p>
      </div>
    );
  };

  if (typeof props.zapping !== "undefined" && props.zapping.length >= 1) {
    let channels = adaptChannelsByCountry(props.zapping);
    return Object.keys(channels).find((item) => {
      return item.toLowerCase() !== userLocale.toLowerCase();
    }) ? (
      <div className="other-tv">
        <div className="sub-title">
          <div className="television-icon" />
          <p>
            <Trans>Watch the match in other countries</Trans>
          </p>
        </div>
        <div className="container">
          {Object.keys(channels).map((each) => {
            return (
              <OtherTvRow key={each} country={each} channels={channels[each]} />
            );
          })}
          <div className="warning">
            <p>
              <span>
                <Trans>Notice</Trans>:
              </span>
              <Trans i18nKey="NoticeWarningText">
                The published lists of live, relayed and on-demand games and
                events published on this website are transmitted by the official
                holders of television rights. They are available on various
                platforms such as terrestrial TV, radio, cable, satellite, IPTV,
                and mobile and desktop applications. When possible, we will
                provide links to events available on platforms offered by
                official broadcasters. Please note that in most cases a digital
                subscription or authentication with an Internet / TV provider
                may be required. We will try to provide the most accurate and
                detailed transmission possible. However, broadcasting times are
                subject to change at any time. If information is missing or
                incorrect.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    ) : (
      renderWarning()
    );
  } else {
    return renderWarning();
  }
}
