import React, { PureComponent } from "react";
import { hashDecode } from "../../utils/miscellaneous";

import Breadcrumbs from "../../components/misc/breadcrumbs/breadcrumbs";
import CompetitionTable from "../../components/competition-table/competition-table";
import TopGames from "../../components/top-games/top-games";

import "./page-all-competitions.css";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";
import { Helmet } from "react-helmet";

class PageAllCompetitions extends PureComponent {
  render() {
    return (
      <div className="default-page page-all-competitions">
        <Helmet>
          <title>{i18n.t("titleAllCompetitions")}</title>
          <meta
            name="description"
            content={i18n.t("metaDescriptionAllCompetitions")}
          ></meta>
        </Helmet>
        <div className="main-container">
          <div className="title">
            {Object.prototype.hasOwnProperty.call(
              this.props.match.params,
              "areaName"
            ) ? (
              <h1>
                <span>
                  {this.props.match.params.areaName.charAt(0).toUpperCase() +
                    this.props.match.params.areaName.slice(1)}
                </span>
                {" - "}
                <Trans i18nKey="countryCompetitionTitle"></Trans>
              </h1>
            ) : (
              <h1>
                <Trans>All football competitions</Trans>
              </h1>
            )}
            <Breadcrumbs
              area_name={this.props.match.params.areaName}
              area_id={hashDecode(this.props.match.params.areaId)}
            />
          </div>
          <CompetitionTable urlParams={this.props.match.params} />
          <h1>
            <Trans
              i18nKey="topGamesToday"
              defaults="Top <0>games</0> today with broadcast"
              components={[<span></span>]}
            ></Trans>
          </h1>
          <TopGames />
          {Object.prototype.hasOwnProperty.call(
            this.props.match.params,
            "areaName"
          ) ? (
            <div>
              <h3>
                <Trans>All football competitions</Trans> <Trans>from</Trans>{" "}
                <span>
                  {this.props.match.params.areaName.charAt(0).toUpperCase() +
                    this.props.match.params.areaName.slice(1)}
                </span>
              </h3>
              <div className="endTexts">
                <p>
                  <Trans
                    i18nKey="countryCompetitionsTextPart1"
                    values={{
                      areaName: this.props.match.params.areaName.charAt(0).toUpperCase() +
                      this.props.match.params.areaName.slice(1),
                    }}
                  ></Trans>
                </p>
                <p>
                  <Trans i18nKey="countryCompetitionsTextPart2"></Trans>
                </p>
                <p>
                  <Trans i18nKey="countryCompetitionsTextPart3"></Trans>
                </p>
                <p>
                  <Trans i18nKey="countryCompetitionsTextPart4"  values={{
                      areaName: this.props.match.params.areaName.charAt(0).toUpperCase() +
                      this.props.match.params.areaName.slice(1),
                    }}></Trans>
                </p>
                <p>
                  <Trans i18nKey="countryCompetitionsTextPart5"></Trans>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="endTexts">
                <p>
                  <Trans i18nKey="allCompetitionsTextPart1"></Trans>
                </p>
                <p>
                  <Trans i18nKey="allCompetitionsTextPart2"></Trans>
                </p>
                <p>
                  <Trans i18nKey="allCompetitionsTextPart3"></Trans>
                </p>
                <p>
                  <Trans i18nKey="allCompetitionsTextPart4"></Trans>
                </p>
                <p>
                  <Trans i18nKey="allCompetitionsTextPart5"></Trans>
                </p>
                <p>
                  <Trans i18nKey="allCompetitionsTextPart6"></Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PageAllCompetitions;
