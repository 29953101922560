import React from "react";
import { useHistory } from "react-router-dom";
import "./footer.css";

import LinkGenerator from "../../routes/linkGenerator";
import AppRoutes from "../../routes/appRoutes";
import { Trans } from "react-i18next";

export default function Footer() {
  const history = useHistory();

  return (
    <footer>
      <div className="semi-footer">
        <b>
          <Trans>Watch your favorite football matches in the front row!</Trans>
        </b>
        {/*         <p>
          <Trans
            i18nKey="footer1Part"
            values={{ country: getName(userLocal) }}
          ></Trans>
        </p>
        <p>
          <Trans i18nKey="footer2Part"></Trans>
        </p>
        <p>
          <Trans i18nKey="footer3Part"></Trans>
        </p> */}
        <p>
          <Trans i18nKey="footer4Part"></Trans>
        </p>
      </div>
      <div className="footer-links">
        <div className="footer-links-list">
          <ul>
            <li>
              <p
                onClick={() =>
                  LinkGenerator.linkTo(history, AppRoutes.contacts)
                }
              >
                <Trans>Contacts</Trans>
              </p>
            </li>
            <li>
              <p
                onClick={() =>
                  LinkGenerator.linkTo(history, AppRoutes.privacyPolicy)
                }
              >
                <Trans>Privacy Policy</Trans>
              </p>
            </li>
            <li>
              <p
                onClick={() =>
                  LinkGenerator.linkTo(history, AppRoutes.termsAndConditions)
                }
              >
                <Trans>Terms and Conditions</Trans>
              </p>
            </li>
          </ul>
        </div>
        <div className="footer-copyright">
          <b>FIRSTROWTV</b>
          <span>© {new Date().getFullYear()}</span>
        </div>
      </div>
    </footer>
  );
}
