export const en = {
  home: "",
  contacts: "contacts",
  termsAndConditions: "terms-and-conditions",
  privacyPolicy: "privacy-policy",
  allCompetitions: "all-competitions",
  allChannels: "all-channels",
  channel: "channel",
  competition: "competition",
  match: "match",
  matches: "matches",
};
