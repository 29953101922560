import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Mail from "../../media/mail-icon.png";

import "./page-terms-and-conditions.css";
import LinkGenerator from "../../routes/linkGenerator";
import appRoutes from "../../routes/appRoutes";
import { Trans } from "react-i18next";
import i18n from "../../translations/i18n";

class PageTermsAndConditions extends Component {
  render() {
    return (
      <div className="default-page page-terms-and-conditions">
        <Helmet>
          {LinkGenerator.generateCanonicalsAndAlternates(
            appRoutes.termsAndConditions
          )}
          <title>{i18n.t("titleTermsConditions")}</title>
          <meta
            name="description"
            content={i18n.t("metaDescriptionTermsConditions")}
          ></meta>
        </Helmet>
        <div className="main-container">
          <h1>
            <Trans>Terms and Conditions</Trans>
          </h1>
          <h6>
            <Trans>
              PLEASE READ THE FOLLOWING CAREFULLY BEFORE ACCESSING AND/OR USING
              ANY PART OF THE WEBSITE.
            </Trans>
          </h6>
          <br></br>
          <h6>
            <Trans>Introduction</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcIntro1"></Trans>
          </p>
          <p>
            <Trans i18nKey="tcIntro2"></Trans>
          </p>
          <p>
            <Trans i18nKey="tcIntro3"></Trans>
          </p>
          <h6>
            <Trans>Third Party Sources and Content</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcThirdParty"></Trans>
          </p>
          <h6>
            <Trans>Privacy Policy</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcPrivacy"></Trans>
          </p>
          <h6>
            <Trans>Liability</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcLiability"></Trans>
          </p>
          <h6>
            <Trans>Rights</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcRights"></Trans>
          </p>
          <h6>
            <Trans>General</Trans>
          </h6>
          <p>
            <Trans i18nKey="tcGeneral"></Trans>:{" "}
            <img
              className="mail-icon"
              src={Mail}
              alt="FirstRowTelevision email account"
            />
            .
          </p>
          <p>
            <Trans i18nKey="tcLastUpdated"></Trans>
          </p>
        </div>
      </div>
    );
  }
}

export default PageTermsAndConditions;
