import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { flagsConverter } from "../../../utils/flag-converter/flags-converter";

import GameTime from "../../misc/game-time/game-time";

import "./game.css";

import classNames from "classnames";
import GameStreamsWidget from "./gameStreamsWidget";
import { Trans } from "react-i18next";
import { getGameLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";
// eslint-disable-next-line no-undef

export default function Game(props) {
  const history = useHistory();
  const countrycode = useSelector(
    (state) =>
      state.competitionsData.allCompetitions[props.topGame.competition_id]
        .countrycode
  );

  return (
    <div className="game">
      <ButtonBase
        className="top-game-competition"
        component={Link}
        to={getGameLink(
          history,
          props.topGame.area_name,
          props.topGame.competition_name,
          props.topGame.team_A_name,
          props.topGame.team_B_name,
          props.topGame.match_id
        )}
      >
        <div className="competition-icon">
          {flagsConverter(props.topGame.competition_id, countrycode)}
        </div>
        <span>{props.topGame.competition_name}</span>
      </ButtonBase>

      <ButtonBase
        className="game-description"
        component={Link}
        to={getGameLink(
          history,
          props.topGame.area_name,
          props.topGame.competition_name,
          props.topGame.team_A_name,
          props.topGame.team_B_name,
          props.topGame.match_id
        )}
      >
        <div className="game-home-team">
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.topGame.team_A_id}.png')`,
            }}
          />
          <p>{props.topGame.team_A_name}</p>
        </div>
        <div
          className={classNames(
            "game-details",
            props.topGame.status.toLowerCase() === "playing" && "live"
          )}
        >
          {props.topGame.status.toLowerCase() === "playing" ? (
            <React.Fragment>
              <div className="live-status">
                <p>
                  <Trans>Live</Trans>
                </p>
                <GameTime game={props.topGame} />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GameTime game={props.topGame} />
              <p>{props.topGame.date_utc}</p>
            </React.Fragment>
          )}
          <p>{props.topGame.round_name}</p>
        </div>
        <div className="game-away-team">
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.topGame.team_B_id}.png')`,
            }}
          />
          <p>{props.topGame.team_B_name}</p>
        </div>
      </ButtonBase>
      {<GameStreamsWidget topGame={props.topGame} />}
    </div>
  );
}
