import * as LanguageRoute from "../routes/languageRoutes";
import React from "react";

class LinkGenerator {
  static BASE_URL = "https://www.firstrowtelevision.com/";
  userLanguage = "en";
  userCountry = "UK";
  constructor(userLocal) {
    this.userLanguage = userLocal;
  }

  static setUserLocal(userLanguage, userCountry) {
    this.userLanguage = userLanguage;
    this.userCountry = userCountry;
  }

  /**
   * All links inside the app must call this function
   * It uses the translated links accordingly to user local
   *
   * @param {string} propsHistory the component props.history
   * @param {string} destination the route from AppRoutes
   * @param {string} path the extra path like competition name given by the API or competition ID
   */
  static linkTo(propsHistory, destination, path = "") {
    if (
      propsHistory.location.pathname !==
      "/" +
      this.userLanguage +
      "/" +
      LanguageRoute[this.userLanguage][destination] +
      path
    ) {
      propsHistory.push(
        "/" +
        this.userLanguage +
        "/" +
        LanguageRoute[this.userLanguage][destination] +
        path
      );
    } else {
      propsHistory.replace(
        "/" +
        this.userLanguage +
        "/" +
        LanguageRoute[this.userLanguage][destination] +
        path
      );
    }
    window.scrollTo(0, 0);
  }

  static getLinkTo(propsHistory, destination, path = "") {
    let currentPath = '';
    if (propsHistory === null) {
      currentPath = window.location.pathname;
    } else {
      currentPath = propsHistory.location.pathname;
    }
    if (
      currentPath !== "/" + this.userLanguage + "/" +
      LanguageRoute[this.userLanguage][destination] +
      path
    ) {
      return (
        "/" +
        this.userLanguage +
        "/" +
        LanguageRoute[this.userLanguage][destination] +
        path
      );
    } else {
      return (
        "/" +
        this.userLanguage +
        "/" +
        LanguageRoute[this.userLanguage][destination] +
        path
      );
    }
  }

  static isMenuActive(path, destination) {
    let urlUserLang = "/" + this.userLanguage.toLowerCase() + "/";
    if (path === urlUserLang && destination === "home") {
      return true;
    } else if (
      path.indexOf(LanguageRoute[this.userLanguage][destination]) !== -1 &&
      destination !== "home"
    ) {
      return true;
    }
    return false;
  }

  static generateCanonicalsAndAlternates(appRoutesPage = "") {
    let linksData = [];
    let mainRoute = LanguageRoute[this.userLanguage][appRoutesPage]
      ? LanguageRoute[this.userLanguage][appRoutesPage]
      : "";
    linksData.push(
      <link
        key="canonical"
        rel="canonical"
        href={LinkGenerator.BASE_URL + this.userLanguage + "/" + mainRoute}
        hrefLang={this.userLanguage}
      />
    );

    Object.keys(LanguageRoute).forEach((language) => {
      let translatedMainRoute = LanguageRoute[language][appRoutesPage]
        ? LanguageRoute[language][appRoutesPage]
        : "";
      linksData.push(
        <link
          key={language}
          rel="alternate"
          href={LinkGenerator.BASE_URL + language + "/" + translatedMainRoute}
          hrefLang={language}
        />
      );
    });
    return linksData;
  }
}

export default LinkGenerator;
