import React, { useEffect, useState } from "react";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";

//import SearchDesktop from './search-desktop/search-desktop';
import Menu from "./menu/menu";

import "./header.css";

import classNames from "classnames";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target && isOpen && event.target.id !== "menuButton") {
        setIsOpen(!isOpen);
      }
      document.removeEventListener("click", handleClickOutside);
    }
    document.addEventListener("click", handleClickOutside);
  });

  return (
    <header>
      <div className="header-wrapper" id="header">
        <button
          id="menuButton"
          onClick={() => setIsOpen(!isOpen)}
          className={classNames(
            "mobile-menu-icon",
            "d-xl-none",
            isOpen && "rotate-menu-icon"
          )}
        />
        <ButtonBase
          className="header-logo"
          component={Link}
          to={"/"}
        ></ButtonBase>

        <Menu isOpen={isOpen} />
        {/* <SearchDesktop /> */}
        {/* <button className="menu-account d-xs-none" /> */}
        {/* <button className="menu-settings" /> */}
        {/* <button className="menu-search" /> */}
      </div>
    </header>
  );
};

export default Header;
