import AppRoutes from "../routes/appRoutes";
import LinkGenerator from "../routes/linkGenerator";
import { slugify, hashEncode } from "./miscellaneous";

/**
 * Redirects to selected match
 * @param {*} history
 * @param {*} area_name
 * @param {*} competition_name
 * @param {*} team_A_name
 * @param {*} team_B_name
 * @param {*} match_id
 */
export function goToGame(
  history,
  area_name,
  competition_name,
  team_A_name,
  team_B_name,
  match_id
) {
  let areaName = slugify(area_name);
  let competitionName = slugify(competition_name);
  let teamA = slugify(team_A_name);
  let teamB = slugify(team_B_name);
  let matchId = match_id;
  LinkGenerator.linkTo(
    history,
    AppRoutes.match,
    `/${areaName}/${competitionName}/${teamA}/${teamB}/${matchId}`
  );
}

/**
 * Returns link to selected match
 * @param {*} history
 * @param {*} area_name
 * @param {*} competition_name
 * @param {*} team_A_name
 * @param {*} team_B_name
 * @param {*} match_id
 */

export function getGameLink(
  history,
  area_name,
  competition_name,
  team_A_name,
  team_B_name,
  match_id
) {
  let areaName = slugify(area_name);
  let competitionName = slugify(competition_name);
  let teamA = slugify(team_A_name);
  let teamB = slugify(team_B_name);
  let matchId = match_id;
  return LinkGenerator.getLinkTo(
    history,
    AppRoutes.match,
    `/${areaName}/${competitionName}/${teamA}/${teamB}/${matchId}`
  );
}

/**
 * Redirects to selected competition
 * @param {*} history
 * @param {*} area_name
 * @param {*} competition_name
 * @param {*} competition_id
 */
export function goToCompetition(
  history,
  area_name,
  competition_name,
  competition_id
) {
  let areaName = slugify(area_name);
  let competitionName = slugify(competition_name);
  let hashCompetitionId = hashEncode(competition_id);
  LinkGenerator.linkTo(
    history,
    AppRoutes.competition,
    `/${areaName}/${competitionName}/${hashCompetitionId}`
  );
}

/**
 * Returns selected competition url
 * @param {*} history
 * @param {*} area_name
 * @param {*} competition_name
 * @param {*} competition_id
 */
export function getCompetitionLink(
  history,
  area_name,
  competition_name,
  competition_id
) {
  let areaName = slugify(area_name);
  let competitionName = slugify(competition_name);
  let hashCompetitionId = hashEncode(competition_id);
  return LinkGenerator.getLinkTo(
    history,
    AppRoutes.competition,
    `/${areaName}/${competitionName}/${hashCompetitionId}`
  );
}

/**
 * Redirects to selected area
 * @param {*} history
 * @param {*} area_name
 * @param {*} area_id
 */
export function goToAreaInfo(history, area_name, area_id) {
  let areaName = slugify(area_name);
  let hashAreaId = hashEncode(area_id);
  LinkGenerator.linkTo(
    history,
    AppRoutes.allCompetitions,
    `/${areaName}/${hashAreaId}`
  );
}

/**
 * Returns selected area Link
 * @param {*} history
 * @param {*} area_name
 * @param {*} area_id
 */
export function getAreaLinkInfo(history, area_name, area_id) {
  let areaName = slugify(area_name);
  let hashAreaId = hashEncode(area_id);
  return LinkGenerator.getLinkTo(
    history,
    AppRoutes.allCompetitions,
    `/${areaName}/${hashAreaId}`
  );
}

/**
 * Redirects to selected channel
 * @param {*} history
 * @param {*} channel
 */
export function goToChannel(history, channelName, event) {
  event.stopPropagation();
  let channelNameSlug = slugify(channelName);
  LinkGenerator.linkTo(history, AppRoutes.channel, `/${channelNameSlug}`);
}

/**
 * Returns selected channel link
 * @param {*} history
 * @param {*} channel
 */
export function getChannelLink(history, channelName) {
  let channelNameSlug = slugify(channelName);
  return LinkGenerator.getLinkTo(
    history,
    AppRoutes.channel,
    `/${channelNameSlug}`
  );
}
