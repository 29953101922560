import React, { PureComponent } from "react";

import TopGames from "../../components/top-games/top-games";
import TableGames from "../../components/table-games/table-games";

import "./page-games.css";
import { Trans } from "react-i18next";

class PageGames extends PureComponent {
  render() {
    return (
      <div className="default-page page-games">
        <div className="main-container">
          <h1>
            <Trans
              i18nKey="findOutFavorite"
              defaults="Find out where to <0>watch</0> live <0>football</0> games!"
              components={[<span></span>]}
            ></Trans>
          </h1>
          <p className="sub-title">
            <Trans>Match Schedule and Live Scores.</Trans>
          </p>
          <TopGames />
          <TableGames />
          <div className="home-page-texts endTexts">
            <p>
              <Trans i18nKey="homePageTextPart1"></Trans>
            </p>
            <p>
              <Trans i18nKey="homePageTextPart2"></Trans>
            </p>
            <p>
              <Trans i18nKey="homePageTextPart3"></Trans>
            </p>
            <p>
              <Trans i18nKey="homePageTextPart4"></Trans>
            </p>
            <p>
              <Trans i18nKey="homePageTextPart5"></Trans>
            </p>
            <p>
              <Trans i18nKey="homePageTextPart6"></Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageGames;
