import React from "react";
import { renderBookmaker } from "../../../utils/miscellaneous";
import classnames from "classnames";
import { getChannelLink } from "../../../utils/handlers";
import { ButtonBase } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";

import "./livestream-channel.css";

export default function LiveStreamChannel(props) {
  const history = useHistory();
  return (
    <a
      className={classnames(
        "livestream-channel",
        `${renderBookmaker(props.channel.name)}-background`
      )}
      href={props.afiliateLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.afiliateLink ? (
        <div
          className={classnames(
            renderBookmaker(props.channel.name),
            "bm_style ico21"
          )}
        ></div>
      ) : (
        <ButtonBase
          className="liveStream-WithOut-Link"
          component={Link}
          to={getChannelLink(history, props.channel.name)}
        >
          <div
            className={classnames(
              renderBookmaker(props.channel.name),
              "bm_style ico21"
            )}
          ></div>
        </ButtonBase>
      )}
    </a>
  );
}
