import React from "react";
import { useSelector } from "react-redux";
import Swiper from "react-id-swiper";

import Game from "../../components/top-games/game/game";

import "../../../node_modules/swiper/css/swiper.css";
import "./top-games.css";
import SpinnerLoader from "../misc/spinner-loader/spinner-loader";

export default function TopGames(props) {
  const topGames = useSelector((state) => state.gamesData.topGames);
  const liveGames = useSelector((state) => state.gamesData.liveGames);
  const allGames = useSelector((state) => state.gamesData.allGames);
  const allCompetitionsIds = useSelector(
    (state) => state.competitionsData.allCompetitionsIds
  );
  const channelsById = useSelector((state) => state.zapping.channelsById);
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );
  const numberOfGamesToShow = 10;
  const games = getGames();
  const params = {
    spaceBetween: 20,
    /*     slidesPerView: games.length > 3 ? 3 : games.length,
    loop:games.length > 3, */
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      deay: 1000,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },

      1199: {
        slidesPerView: props.cards || 3,
      },
    },
  };

  function verifyIfHasTvOrStream(game) {
    const tvChannels = [];
    const liveStreams = [];
    if (Object.prototype.hasOwnProperty.call(game, "zapping")) {
      game.zapping.forEach((element) => {
        if (Object.prototype.hasOwnProperty.call(element, "channel_id")) {
          if (
            userLocale.toLowerCase() === element.locale.toLowerCase() &&
            channelsById &&
            Object.prototype.hasOwnProperty.call(
              channelsById,
              element.channel_id
            ) &&
            channelsById[element.channel_id].bookmaker_name === ""
          ) {
            tvChannels.push(element);
          }
        }
        if (
          userLocale.toLowerCase() === element.locale.toLowerCase() &&
          channelsById &&
          Object.prototype.hasOwnProperty.call(
            channelsById,
            element.channel_id
          ) &&
          channelsById[element.channel_id].bookmaker_name !== "" &&
          Object.prototype.hasOwnProperty.call(
            channelsById[element.channel_id],
            "afiliateLink"
          )
        ) {
          liveStreams.push(element);
        }
      });
    }
    if (tvChannels.length > 0 || liveStreams.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function getGames() {
    let games = [];
    topGames.slice(0, numberOfGamesToShow).forEach((each) => {
      if (verifyIfHasTvOrStream(each)) {
        if (Object.prototype.hasOwnProperty.call(liveGames, each.match_id)) {
          games.push(
            <div key={each.match_id}>
              <Game topGame={allGames[each.match_id]} />
            </div>
          );
        } else {
          games.push(
            <div key={each.match_id}>
              <Game topGame={each} />
            </div>
          );
        }
      }
    });
    return games;
  }

  return topGames &&
    topGames.length > 1 &&
    allCompetitionsIds.length > 0 &&
    games.length > 0 ? (
    <div className="top-games">
      <Swiper {...params}>{games}</Swiper>
    </div>
  ) : (
    <SpinnerLoader />
  );
}
