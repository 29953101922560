import React from "react";

import GameTime from "../misc/game-time/game-time";

import "./full-game-description.css";

import classNames from "classnames";
import GameHistory from "../table-games/competition-row/game-row/more-game-info/game-history/game-history";
import GameStreamsWidget from "../top-games/game/gameStreamsWidget";
import { Trans } from "react-i18next";

export default function FullGameDescription(props) {
  const renderGameStatus = () => {
    switch (props.gameInfo.status.toUpperCase()) {
      case "PLAYING":
        return (
          <React.Fragment>
            <p className="live-txt">
              <Trans>Live</Trans>
            </p>
            <GameTime game={props.gameInfo} />
            <strong>
              {props.gameInfo.fs_A} - {props.gameInfo.fs_B}
            </strong>
            {props.gameInfo.ets_A !== "" && props.gameInfo.ets_B !== "" && (
              <p>
                (Prol. {props.gameInfo.ets_A} - {props.gameInfo.ets_B})
              </p>
            )}
            {props.gameInfo.ps_A !== "" && props.gameInfo.ps_B !== "" && (
              <p>
                (Pen. {props.gameInfo.ps_A} - {props.gameInfo.ps_B})
              </p>
            )}
            <p>{props.gameInfo.date_utc}</p>
          </React.Fragment>
        );
      case "FIXTURE":
        return (
          <React.Fragment>
            <p className="full-game-status">
              <Trans>FIXTURE</Trans>
            </p>
            <strong>VS</strong>
            <GameTime game={props.gameInfo} />
            <p>{props.gameInfo.date_utc}</p>
          </React.Fragment>
        );
      case "PLAYED":
        return (
          <React.Fragment>
            <p className="full-game-status">
              <Trans>FINISHED</Trans>
            </p>
            <strong>
              {props.gameInfo.fs_A} - {props.gameInfo.fs_B}
            </strong>
            {props.gameInfo.ets_A !== "" && props.gameInfo.ets_B !== "" && (
              <p>
                (Prol. {props.gameInfo.ets_A} - {props.gameInfo.ets_B})
              </p>
            )}
            {props.gameInfo.ps_A !== "" && props.gameInfo.ps_B !== "" && (
              <p>
                (Pen. {props.gameInfo.ps_A} - {props.gameInfo.ps_B})
              </p>
            )}
            <p>{props.gameInfo.date_utc}</p>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <strong>VS</strong>
            <p>{props.gameInfo.time_utc}</p>
            <p>{props.gameInfo.date_utc}</p>
          </React.Fragment>
        );
    }
  };

  return Object.keys(props.gameInfo).length < 1 ? null : (
    <div
      className={classNames(
        "full-game-description",
        props.gameInfo.status.toUpperCase() === "PLAYING" && "live"
      )}
    >
      <div className="top-cell">
        <div className="game-home-team">
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.gameInfo.team_A_id}.png')`,
            }}
          />
          <p>{props.gameInfo.team_A_name}</p>
        </div>
        <div className="game-details">{renderGameStatus()}</div>
        <div className="game-away-team">
          <div
            className="game-team-icon"
            style={{
              backgroundImage: `url('https://academia.r.worldssl.net/addons/default/modules/stats/img/team/150x150/${props.gameInfo.team_B_id}.png')`,
            }}
          />
          <p>{props.gameInfo.team_B_name}</p>
        </div>
      </div>
      <div className="bottom-cell">
        <GameHistory
          gameId={props.gameInfo.match_id}
          team_A_id={props.gameInfo.team_A_id}
          team_B_id={props.gameInfo.team_B_id}
          events={props.gameInfo.events}
        />
      </div>
      {<GameStreamsWidget topGame={props.gameInfo} />}
    </div>
  );
}
