import socketio from "socket.io-client";
const URL = "https://www.academiadasapostas.com:1555";
var ws = null;
var connected = false;

function connectWebSocket() {
  return new Promise(function (resolve, reject) {
    if (connected) {
      console.log("ALLREADY CONNECTED");
      return resolve();
    }
    ws = socketio(URL, { forceNew: false, autoConnect: true });
    ws.on("connect", function () {
      console.log("WEBSocket connected ---------------------------");
      connected = true;
      resolve();
    });
    ws.on("error", function (data) {
      console.log("WEBSocket ERROR", data);
      connected = false;
      reject();
    });
    ws.on("close", function (data) {
      console.log("WEBSocket CLOSE", data);
      connected = false;
      reject();
    });
    ws.open();
  });
}

export function getWebSocketTodayGamesIds() {
  return new Promise(function (resolve, reject) {
    connectWebSocket()
      .then(() => {
        ws.emit("listGames");
        ws.on("listGames", function (today_games) {
          // console.log("todaygames", today_games);
          resolve(today_games);
        });
      })
      .catch(() => {
        reject();
      });
  });
}
export function subscriveGames(callBack, matchIds) {
  return new Promise(function (resolve, reject) {
    connectWebSocket()
      .then(() => {
        ws.emit("subscribe", {
          type: ["soccer.match", "soccer.events"],
          games: matchIds,
        });
        ws.on("soccer.match", function (data) {
          callBack(data);
        });
        ws.on("soccer.events", function (data) {
          callBack(data);
        });
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function closeSocket() {
  console.log("closing");
  connected = false;
  if (ws) {
    ws.close();
    ws.off();
  }
}
