import React from "react";
import { Trans } from "react-i18next";

import "./game-status.css";

export default function GameStatus() {
  return (
    <div className="game-status d-xs-none">
      <Trans>Live</Trans>
    </div>
  );
}
