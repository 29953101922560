import React from "react";
import { connect } from "react-redux";
import { getDataFromAcademia } from "../../utils/axios";

import NextAvailableGame from "./next-available-game/next-available-game";
import SpinnerLoader from "../misc/spinner-loader/spinner-loader";

import "./next-games.css";
import { Trans } from "react-i18next";

class NextGames extends React.Component {
  numberOfNextGames = 3;

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      competitionGames: [],
    };
  }

  componentDidMount() {
    this.getAcademiaCompetitionGames(this.props.competition_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.competition_id !== this.props.competition_id) {
      this.getAcademiaCompetitionGames(this.props.competition_id);
    }
  }

  getAcademiaCompetitionGames = (compId) => {
    this.setState({
      isFetching: true,
    });
    const request = getDataFromAcademia({
      method: "get",
      url: `/api/?mode=web&timezone=Europe/London&jsonrpc={"jsonrpc":"2.0","method":"get_matches_by_competition","params":{"competitions":[${compId}], "total_days":25, "direction":1},"id":1}`,
    });
    return request
      .then((response) => {
        this.setState({
          competitionGames: response.data.result,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.log(`Failed to get games list. ${error}`);
        this.setState({
          competitionGames: [],
          isFetching: false,
        });
      });
  };

  render() {
    return this.state.competitionGames.length >= 1 &&
      this.state.competitionGames.find((item) => {
        return item.match_id !== this.props.match_id;
      }) ? (
      this.state.isFetching ? (
        <SpinnerLoader small />
      ) : (
        <div className="next-games">
          <div className="sub-title">
            <div className="television-icon" />
            <p>
              <Trans>Next games in</Trans> {this.props.area_name} (
              {this.props.competition_name})
            </p>
          </div>
          <div className="container">
            {this.state.competitionGames
              .filter((filtered) => filtered.match_id !== this.props.match_id)
              .slice(0, this.numberOfNextGames)
              .map((each) => {
                return <NextAvailableGame key={each.match_id} game={each} />;
              })}
          </div>
        </div>
      )
    ) : (
      <div className="next-games empty-widget d-xs-none">
        <p>
          <i>
            <Trans>No next games in</Trans>
          </i>{" "}
          {this.props.area_name} ({this.props.competition_name})
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userLocal: state.userConfigs.userCountryInfo.country_code,
  };
};

export default connect(mapStateToProps, null)(NextGames);
