export const es = {
  home: "",
  contacts: "contactos",
  termsAndConditions: "terminos-y-condiciones",
  privacyPolicy: "politica-de-privacidad",
  allCompetitions: "todas-competiciones",
  allChannels: "todos-canales",
  channel: "canal",
  competition: "competicion",
  match: "juego",
  matches: "juegos",
};
