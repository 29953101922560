import React from "react";
import { useSelector } from "react-redux";
import { simpleFlag } from "../../../utils/flag-converter/flags-converter";
import "./other-tv-row.css";
const { getName } = require("country-list");

export default function OtherTvRow(props) {
  const userLocale = useSelector(
    (state) => state.userConfigs.userCountryInfo.country_code
  );

  const renderCountryName = () => {
    return props.country === "uk" ? (
      <p className="country-name">United Kingdom</p>
    ) : (
      <p className="country-name">{getName(props.country)}</p>
    );
  };

  return userLocale.toLowerCase() === props.country.toLowerCase() ? null : (
    <div className="other-tv-row">
      <div className="left-cell">
        <div className="flag">{simpleFlag(props.country)}</div>
        {renderCountryName()}
      </div>
      <div className="right-cell">
        {Object.keys(props.channels).map((each, index) => {
          if (index < Object.keys(props.channels).length - 1) {
            return <span key={index}>{props.channels[each]}, </span>;
          } else {
            return <span key={index}>{props.channels[each]}</span>;
          }
        })}
      </div>
    </div>
  );
}
