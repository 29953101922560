export function adaptChannelsByCountry(obj) {
  let countryList = {};
  obj.forEach((each) => {
    countryList = {
      ...countryList,
      [each.locale]: {
        ...countryList[each.locale],
        [each.channel_id]: each.zapping_name,
      },
    };
  });

  return countryList;
}
